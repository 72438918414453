export function sprintf(str: string, ...args: Array<number|string>) {

    let i = 0;

    return str.replace(/%((%)|s|d|f)/g, function (m) {
        // m is the matched format, e.g. %s, %d
        let val: string | number;

        if (m[2]) {
            val = m[2];
        }
        else {
            val = args[i];
            // A switch statement so that the formatter can be extended. Default is %s
            switch (m) {
                case '%d':
                    val = typeof val === 'number' ? Math.floor(val) : parseInt(val);

                    if (isNaN(val)) {
                        val = 0;
                    }
                    break;

                case '%f':
                    val = typeof val === 'number' ? val : parseFloat(val);

                    if (isNaN(val)) {
                        val = 0;
                    }
                    break;
            }
            i++;
        }

        return String(val);
    });
}

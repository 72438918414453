
import {defineComponent} from 'vue';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';
import PracticePaper from '@/components/exam/practice-paper.vue';

export default defineComponent({
    name: 'practice-view',

    components: {
        BreadCrumbs,
        PracticePaper
    },

    props: {
        subject: String,
        model: String,
        cid: String,
        random: Boolean,
        pid: Number
    }
});

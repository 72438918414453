
import {defineComponent} from 'vue';
import {mapState} from 'vuex';

export default defineComponent({
    name: 'main-footer',

    computed: {
        ...mapState('training', ['config'])
    }
});

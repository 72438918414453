<template>
    <div class="com-home-jiaxiao-tuijian com-part-home">
        <div class="part-home-title">
            <hr class="hr">
            <div class="title-text">驾校推荐</div>
            <div class="title-text2"></div>
        </div>
        <div class="jiaxiao-tuijian-container">
            <ul class="school-list clearfix">

                <li v-for="item in schools" :key="item.id">
                    <div class="item-top clearfix">
                        <div class="col1 left">
                            <div class="div1">
                                <span class="name">{{item.name}}</span>
                                <div class="star-w-s">
                                    <div class="bfb" :style="{width: item.rating / 5 + '%'}"></div>
                                </div>
                            </div>
                            <div class="div2">
                                <span v-if="item.rating > 4">口碑好</span>
                                <!-- <span>规模大</span> -->
                                <!-- <span>拿本快</span> -->
                                <span v-if="item.pickup">包接送</span>
                            </div>
                        </div>
                        <div class="col2 left">
                            <p class="price red">￥{{item.charge}}</p>
                        </div>
                        <div class="col3 right">
                            <button class="btn-baoming">学车报名</button>
                        </div>
                    </div>
                    <!-- <div class="item-content"></div> -->
                    <div class="tip">
                        <span class="renzheng"></span>
                    </div>
                </li>

            </ul>

            <!-- <div class="btn-bar">
                <a>查看更多</a>
            </div> -->
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, toRefs, Ref} from 'vue';
import {useSchoolRank} from '@/compositions/training-school';

export default defineComponent({

    props: {
        city_id: Number
    },

    setup: (props) => {

        const {city_id} = toRefs(props);

        const defaultList = useSchoolRank('recommand', 6, city_id as Ref<number>);

        return {
            ...defaultList
        };
    }
});
</script>
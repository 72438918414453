
import {defineComponent} from 'vue';
import {mapState} from 'vuex';

export default defineComponent({

    computed: {
        ...mapState('app', ['cityData']),

        alphaSortCitys: function () {
            const cityData = this.cityData;
            const citys: any[] = [];
            const cityMap = {};

            cityData.forEach((p) => {
                let iCitys = cityMap[p.i];

                if (!iCitys) {
                    iCitys = cityMap[p.i] = {
                        i: p.i,
                        c: [p]
                    };

                    citys.push(iCitys);
                }
                else {
                    iCitys.c.push(p);
                }
            });

            return citys.sort((a, b) => {
                return a.i.charCodeAt() - b.i.charCodeAt();
            });
        },

        specialCitys: function () {

            return this.cityData.filter((p) => {
                return p.c && p.c.length === 1 && p.n === p.c[0].n;
            });
        }
    },

    methods: {
        selectCity: function (city) {
            this.$store.commit('app/CITY-ID', city.v);

            this.close();
        },

        close: function () {

            this.$emit('close');
        },

        toAlpha: function (alpha) {
            let el = this.$refs[alpha];
            let scrollContainer = this.$refs.scrollContainer;

            scrollContainer.scrollTop = el.offsetTop;
        }
    }
});

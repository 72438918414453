
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        title: String,
        confirmText: {
            type: String,
            default: '确定'
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirm: {
            type: Function
        },
        mask: {
            type: Boolean,
            default: true
        },
        autoclose: {
            type: Number,
            default: 0
        }
    },

    mounted: function () {

        if (this.autoclose) {
            setTimeout(() => {
                this.cancel();
            }, this.autoclose);
        }
    },

    methods: {
        ok: function () {

            if (typeof this.confirm === 'function') {
                this.confirm(true);
            }

            this.$emit('confirm', true);
        },

        cancel: function () {

            if (typeof this.confirm === 'function') {
                this.confirm(false);
            }

            this.$emit('confirm', false);
        }
    }
});


import {defineComponent} from 'vue';
import QrCode from '@/components/widgets/qr-code.vue';

export default defineComponent({

    name: 'login-modal',

    components: {
        QrCode
    },

    props: {

    },

    data: function () {
        return {
            qrcode: (location.host) + '/jiakao/pages/vip'
        };
    },

    methods: {
        close: function () {
            this.$store.commit('app/H5-QRCODE-MODAL', false);
        }
    }
});

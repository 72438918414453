import {HTTP_REQUEST_URL, HEADER, TOKENNAME} from '@/config/app';
import {toLogin, checkLogin} from '../libs/login';
import store from '../store';
import {mergeUrlParams} from '@/utils/url';

/**
 * 发送请求
 */
function baseRequest(url: string, method: string, data: any, {noAuth = false, noVerify = false, raw = false}) {

    let Url = HTTP_REQUEST_URL,
        header: HeadersInit = {...HEADER};

    if (!noAuth) {
        //登录过期自动登录
        if (!store.state.app.token && !checkLogin()) {
            toLogin();
            return Promise.reject({
                msg: '未登陆'
            });
        }
    }

    if (store.state.app.token) header[TOKENNAME] = 'Bearer ' + store.state.app.token;
    header['lang'] = store.state.lang.lang || 'zh-cn';

    header['x-client'] = 'pc';

    if (!method) {
        method = 'GET';
    }

    method = method.toUpperCase();

    url = Url + '/api/' + url;
    if (method === 'GET' || method === 'HEAD') {
        url = mergeUrlParams(url, data);
    }

    let body;

    if (data) {
        if (raw) {
            body = data;
            delete header['content-type'];
        }
        else if (['GET', 'HEAD'].indexOf(method) === -1) {
            body = JSON.stringify(data);
        }
    }

    return fetch(url, {
        method: method || 'GET',
        headers: header,
        body: body
    }).then((res) => res.json(), (e) => {
        console.error(e);
        return Promise.reject('请求失败');
    }).then((res) => {
        if (noVerify)
            return res;
        else if (res.status == 200)
            return res;
        else if ([410000, 410001, 410002].indexOf(res.status) !== -1) {
            toLogin();
            return Promise.reject(res);
        }
        else
            return Promise.reject(res.msg || '系统错误');
    });
}

const request: {[key: string]: TReqeustMethod} = {};

interface TReqeustMethod {
    (api: string, data?: any, opt?: any): Promise<any>;
}

['options', 'get', 'post', 'put', 'head', 'delete', 'trace', 'connect'].forEach((method) => {
    request[method] = (api, data, opt) => baseRequest(api, method, data, opt || {})
});

export default request;

export function jsonp (url, callback) {

    let script = document.createElement('script');
    let callbackName = 'callback' + Date.now();

    window[callbackName] = callback;

    script.src = url.indexOf('?') === -1 ? url + '?callback=' + callbackName : url + '&callback=' + callbackName;
    script.type = 'text/javascript';

    document.body.appendChild(script);
}

export function polling (apiCall, params, callback, wait = 1000) {

    let stop: boolean | (() => void) = false;

    apiCall(params).then((res) => {
        if (!stop && callback(res) !== false) {
            setTimeout(() => {
                if (!stop) {
                    stop = polling(apiCall, params, callback, wait);
                }
            }, wait);
        }
    });

    return () => {
        if (typeof stop === 'function') {
            return stop();
        }
        else if (!stop) {
            stop = true;
        }
    };
}
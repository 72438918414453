
import { defineComponent, toRefs, Ref } from 'vue';

import {modelText, subjectText} from '@/utils/training';
import {useCategoryList} from '@/compositions/question-category';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';

export default defineComponent({

    components: {
        BreadCrumbs
    },

    props: {
        subject: String,
        model: String,
        pids: String
    },

    setup: function (props) {

        const {subject, model, pids} = toRefs(props);

        return useCategoryList(subject as Ref<string>, model as Ref<string>, pids as Ref<string>);
    },

    computed: {
        typeTitle: function () {
            return modelText(this.model) + subjectText(this.subject);
        },

        title: function () {
            if (this.pids === '2') {
                return '精选题库';
            }
            else {
                return '分类练习';
            }
        }
    }
});

import {RouteRecordRaw} from 'vue-router';

import Home from '../views/home.vue';
import CommonLayout from '../views/exam/layout.vue';
import ExamIndex from '../views/exam/index.vue';
import ExamPractice from '../views/exam/practice.vue';
import ExamMock from '../views/exam/mock.vue';
import ExamAward from '../views/exam/award.vue';
import ExamCates from '../views/exam/cates.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Home,
        meta: {
            title: '首页'
        }
    },
    {
        path: '/exam',
        component: CommonLayout,
        meta: {
            title: '模拟考试'
        },
        children: [
            {
                path: '',
                redirect: '/exam/index',
            },
            {
                path: 'index',
                component: ExamIndex
            },
            {
                path: 'practice',
                props: (route) => {
                    return {
                        subject: route.query.subject,
                        model: route.query.model,
                        cid: route.query.cid || '',
                        random: Boolean(route.query.random),
                        pid: Number(route.query.pid) || 0
                    };
                },
                component: ExamPractice,
                meta: {
                    title: '顺序练习'
                }
            },
            {
                path: 'mock',
                component: ExamMock,
                meta: {
                    title: '模拟考试'
                }
            },
            {
                path: 'award',
                component: ExamAward,
                meta: {
                    title: '考试成绩'
                }
            },
            {
                path: 'cates',
                props: (route) => {
                    return {
                        subject: route.query.subject,
                        model: route.query.model,
                        pids: route.query.type
                    };
                },
                component: ExamCates,
                meta: {
                    title: '分类练习'
                }
            }
        ]
    },
    {
        path: '/videos',
        component: CommonLayout,
        meta: {
            title: '视频学车'
        },
        children: [
            {
                path: '',
                redirect: '/videos/index',
            },
            {
                path: 'index',
                props: (route) => {
                    return {
                        subject: route.query.subject,
                        model: route.query.model
                    };
                },
                component: () => import('../views/videos/index.vue')
            },
            {
                name: 'video-detail',
                path: 'detail',
                props: (route) => {
                    return {
                        id: parseInt(route.query.id as string) || 0
                    };
                },
                component: () => import('../views/videos/detail.vue')
            }
        ]
    },
    {
        path: '/school',
        component: CommonLayout,
        meta: {
            title: '找驾校'
        },
        children: [
            {
                path: '',
                redirect: '/school/index',
            },
            {
                path: 'index',
                props: (route) => {
                    return {
                        page: parseInt(route.query.page as string) || 1
                    };
                },
                component: () => import('../views/school/index.vue')
            },
            {
                path: 'coach',
                props: (route) => {
                    return {
                        id: parseInt(route.query.id as string)
                    };
                },
                component: () => import('../views/school/coach.vue')
            },
            {
                path: 'detail',
                name: 'school-detail',
                props: (route) => {
                    return {
                        id: parseInt(route.query.id as string)
                    };
                },
                component: () => import('../views/school/detail.vue')
            },
            {
                path: 'registry',
                name: 'school-registry',
                component: () => import('../views/school/registry.vue')
            }
        ]
    },
    {
        path: '/bbs',
        component: CommonLayout,
        meta: {
            title: '驾考圈'
        },
        children: [
            {
                path: '',
                redirect: '/bbs/index',
            },
            {
                path: 'index',
                props: (route) => {
                    return {
                        page: parseInt(route.query.page as string) || 1
                    };
                },
                component: () => import('../views/bbs/index.vue')
            },
            {
                path: 'forum',
                name: 'bbs-forum',
                props: (route) => {
                    return {
                        id: parseInt(route.query.id as string) || 0,
                        page: parseInt(route.query.page as string) || 1
                    };
                },
                component: () => import('../views/bbs/forum.vue')
            },
            {
                path: 'thread',
                name: 'bbs-thread',
                props: (route) => {
                    return {
                        id: parseInt(route.query.id as string) || 0,
                        page: parseInt(route.query.page as string) || 1
                    };
                },
                component: () => import('../views/bbs/thread.vue')
            }
        ]
    },
    {
        path: '/signs',
        component: CommonLayout,
        meta: {
            title: '交通标志'
        },
        children: [
            {
                path: '',
                redirect: '/signs/index',
            },
            {
                path: 'index',
                component: () => import('../views/signs.vue')
            },
        ]
    }
];

export default routes;
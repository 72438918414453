import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import LangPlugin from '@/plugins/lang';
import ModalPlugin from '@/plugins/modal';
import qqMapPlugin from '@/plugins/qq-map';

import '@/assets/css/main.css';

const app = createApp(App);

app.use(store)
app.use(router);
app.use(LangPlugin, {store});
app.use(ModalPlugin, {store});
app.use(qqMapPlugin, {gl: true});

app.mount('#app');

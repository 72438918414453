export function queryString (params: {[key: string]: string}) {

    let queryString = '';

    for (let key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key) && typeof params[key] !== 'undefined') {
            queryString += ['&', key, '=', encodeURIComponent(params[key])].join('');
        }
    }

    return queryString ? queryString.slice(1) : queryString;
}

export function mergeUrlParams (url: string, params: {[key: string]: string}) {

    let query = queryString(params);

    if (query) {
        if (url.indexOf('?') === -1) {
            return url + '?' + query;
        }
        else {
            return url + '&' + query;
        }
    }

    return url;
}
import {Plugin} from 'vue';

const win: any = window;

let tMap;
let glScript;
let glCallbacks = [];

let qqMap;
let script;
let callbacks = [];

win.qqMapInit = () => {

    qqMap = win.qq.maps;

    let fn;

    while (callbacks.length) {
        fn = callbacks.shift();
        fn(qqMap);
    }
}

win.TMapInit = () => {

    tMap = win.TMap;

    let fn;

    while (glCallbacks.length) {
        fn = glCallbacks.shift();
        fn(tMap);
    }
}

function loadGLScript(key = 'OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77') {

    glScript = document.createElement("script");
    glScript.type = "text/javascript";
    glScript.src = `https://map.qq.com/api/gljs?v=1.exp&key=${key}&callback=TMapInit`;

    document.body.appendChild(glScript);
}

function loadScript(gl = true, key = 'OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77') {

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://map.qq.com/api/js?v=2.exp&key=${key}&callback=qqMapInit`;

    document.body.appendChild(script);
}

export default {

    install(app, options) {

        if (options.gl) {

            app.config.globalProperties.$TMap = (key, callback) => {

                if (tMap) {
                    callback(tMap);
                }
                else if (glScript) {
                    glCallbacks.push(callback);
                }
                else {
                    glCallbacks.push(callback);
                    loadGLScript(key);
                }
            };
        }
        else {

            app.config.globalProperties.$qqMap = (key, callback) => {
                if (qqMap) {
                    callback(qqMap);
                }
                else if (script) {
                    callbacks.push(callback);
                }
                else {
                    callbacks.push(callback);
                    loadScript(key);
                }
            };
        }
    }
}

function arc (angle) {
    return angle * Math.PI / 180;
}

/**
 * 球面距离，经纬度换算距离
 * @param  {[type]} point1 [description]
 * @param  {[type]} point2 [description]
 * @return {[type]}        [description]
 */
export function distance (point1, point2) {

    let lng1 = arc(point1.lng);
    let lat1 = arc(point1.lat);
    let lng2 = arc(point2.lng);
    let lat2 = arc(point2.lat);
    let R = 6370986;

    return R * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng1 - lng2) + Math.sin(lat1) * Math.sin(lat2));
}

//token
export const LOGIN_STATUS = 'LOGIN_STATUS_TOKEN';

// uid
export const UID ='UID';

export const USER_INFO = 'USER_INFO';

export const EXPIRES_TIME = 'EXPIRES_TIME';

export const WX_AUTH = 'WX_AUTH';

export const STATE_KEY = 'wx_authorize_state';

export const LOGINTYPE = 'loginType';

export const BACK_URL = 'login_back_url';

export const STATE_R_KEY = 'roution_authorize_state';

export const LOGO_URL = 'LOGO_URL';

//模板缓存
export const SUBSCRIBE_MESSAGE = 'SUBSCRIBE_MESSAGE';

export const TIPS_KEY = 'TIPS_KEY';

export const SPREAD = 'spread';

//缓存经度
export const CACHE_LONGITUDE = 'LONGITUDE';

//缓存纬度
export const CACHE_LATITUDE = 'LATITUDE';

import {defineComponent} from 'vue';

import {timeFormat} from '@/utils/datetime';
import {mergeUrlParams} from '@/utils/url';

export default defineComponent({

    data: function () {
        let query = this.$route.query;
        let result: string = query.result as string;

        return {
            mockResult: JSON.parse(result)
        };
    },

    computed: {
        timecost: function () {

            return timeFormat(this.mockResult.timecost);
        },

        missCount: function () {

            const result = this.mockResult;

            return result.total - result.correct - result.error;
        },

        pass: function () {

            return this.mockResult.score >= 90;
        }
    },

    methods: {

        again: function () {

            let url = mergeUrlParams('/exam/mock', {
                subject: this.mockResult.subject,
                model: this.mockResult.model
            });

            this.$router.replace(url);
        }
    }
});

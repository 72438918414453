import {Plugin} from 'vue';

const LangPlugin: Plugin = {
    install: (app, {store}) => {
        app.config.globalProperties.$trans = (key: string, ...args: Array<string|number>) => {

            return store.getters['lang/trans'](key, ...args);
        };
    }
};

export default LangPlugin;
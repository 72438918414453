<template>
    <div class="com-mnks-question-detail" :class="{'show-answer': showAnswer}">
        <div class="detail-content">
            <div class="timu">
                <p class="timu-text" v-html="titleHTML"></p>
                <!-- <button class="btn-shoucang ">收藏</button> -->
            </div>
            <div class="answer-w clearfix">
                <div class="options-w">

                    <question-option v-for="(item, index) in options"
                            :option="item"
                            :index="index"
                            :showAnswer="showAnswer"
                            :answerWrong="answerWrong"
                            :userAnswer="userAnswer ? userAnswer.answer : selected"
                            :key="index"
                            @select-option="select(index)" />

                    <button class="btn-answer-ok" v-if="isMultiAnswer && !userAnswer" @click="confirmAnswer">{{$trans('确定')}}</button>
                </div>

                <div class="media-w right" v-if="question.image">
                    <img :src="question.image" />
                </div>
            </div>
        </div>
        <div class="option-type-msg">
            <span v-if="question.type === 1">{{$trans('判断题，请判断对错！')}}</span>
            <span v-else-if="question.type === 2">{{$trans('单选题，请选择你认为正确的答案！')}}</span>
            <span v-else-if="question.type === 3">{{$trans('多选题，请至少选择两个正确答案！')}}</span>
        </div>
    </div>
</template>

<script>
import {defineComponent, toRefs} from 'vue';

import {useQuestionDetail} from '@/compositions/question-paper';
import QuestionOption from './question-option.vue';
import {replaceMark} from '@/utils/training';

export default defineComponent({
    name: 'question-detail',

    components: {
        QuestionOption
    },

    props: {
        question: Object,
        current: Number,
        total: Number,
        userAnswer: {
            type: Object
        }
    },

    setup: function (props) {

        let {question, userAnswer} = toRefs(props);

        return useQuestionDetail(question, userAnswer);
    },

    data: function () {
        return {

        };
    },

    computed: {

        locked: function () {
            return Boolean(this.userAnswer);
        },

        showAnswer: function () {

            return Boolean(this.locked);
        },

        answerWrong: function () {

            return this.showAnswer && !this.userAnswer.correct;
        },

        titleHTML: function () {

            return [this.current + 1, '/', this.total, '、', replaceMark(this.question.title)].join('');
        }
    },

    methods: {

        select: function (index) {

            if (this.selectOption(index)) {
                if (!this.isMultiAnswer) {
                    this.confirmAnswer();
                }
            }
        },

        abcIndex: function (index) {
            return 'ABCDEF'[index];
        },

        confirmAnswer: function () {

            let userAnswer = this.selected;
            let correct;

            if (this.question.type === 1) {
                userAnswer = userAnswer === 1 ? 1 : 0; // 判断题第一个选项正确，第二个选项错误
            }

            correct = this.question.answer === userAnswer;

            if (correct) {
                if (this.failed) {
                    this.$store.commit('training/REMOVE-FAILED-QUESTION', this.question.id);
                }
            }
            else {
                if (!this.free) {
                    this.$store.commit('training/ADD-FAILED-QUESTION', {
                        id: this.question.id,
                        subject: this.subject,
                        model: this.model,
                        lang: this.$store.state.lang.lang
                    });
                }
            }

            this.$emit('confirm-answer', {
                id: this.question.id,
                answer: this.selected,
                correct: correct
            });
        }
    }
});
</script>

<style lang="scss">
.com-mnks-question-detail {
    &.show-answer .mark {
        color: #f0ad4e;
    }

    &.show-answer .keyword {
        color: #f0ad4e;
    }
}
</style>
import {Plugin} from 'vue';

const ModalPlugin: Plugin = {
    install: (app, {store}) => {
        app.config.globalProperties.$modal = (modal) => {
            return store.dispatch('app/SHOW-CONFIRM', modal);
        };

        app.config.globalProperties.$toast = (msg) => {
            return store.dispatch('app/SHOW-CONFIRM', {
                title: msg,
                autoclose: 2000,
                mask: false,
                cancelText: '',
                confirmText: ''
            });
        };
    }
};

export default ModalPlugin;
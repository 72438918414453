import request from '@/utils/request';

export function fetchConfig () {

    return request.get('v2/training/config', {}, {noAuth: true});
}

export function fetchCateList (query) {

    return request.get('v2/training/categories', {
        ...query
    });
}

export function fetchFreeQuestions (query) {

    return request.get('v2/training/questions', {
        free: 1,
        ...query
    }, {noAuth: true});
}

export function fetchPracticeQuestions (query) {

    return request.get('v2/training/questions', {
        cid: query.cid,
        model: query.model,
        subject: query.subject,
        pid: query.pid
    });
}

export function fetchExamQuestions (query) {

    return request.get('v2/training/exam_questions', {
        model: query.model,
        subject: query.subject
    });
}

export function fetchQuestinsByIds (ids) {

    return request.get('v2/training/questions', {
        ids: ids
    });
}

export function activeWithCode (code) {

    return request.get('v2/training/user_access/active', {
        code: code
    });
}

export function getUserAccessList () {

    return request.get('v2/training/user_access/index');
}

export function getCityData () {

    return request.get('city_list', {}, {noAuth: true});
}

export function getSchoolList (data) {

    return request.get('v2/training/schools', data, {noAuth: true});
}

export function getSchoolRank (data) {

    return request.get('v2/training/schools/rank', data, {noAuth: true});
}

export function getSchoolInfo (id) {

    return request.get('v2/training/schools/' + id, {}, {noAuth: true});
}

export function getCoachList (data) {

    return request.get('v2/training/coach', data, {noAuth: true});
}

export function getCoachRank (data) {

    return request.get('v2/training/coach/rank', data, {noAuth: true});
}

export function getCoachInfo (id) {

    return request.get('v2/training/coach/' + id, {}, {noAuth: true});
}

export function getClassList (data) {

    return request.get('v2/training/class', data, {noAuth: true});
}

export function getClassInfo (id) {

    return request.get('v2/training/class/' + id, {}, {noAuth: true});
}

export function getCityInfo (data) {

    return request.get('v2/training/cityinfo', data, {noAuth: true});
}

export function logExamResult (data) {

    return request.post('v2/training/exam_result', data);
}

export function examResultList (data = {}) {

    return request.get('v2/training/exam_result', {page: 1, limit: 100, ...data});
}

export function enroll (data) {

    return request.post('v2/training/enroll', data, {noAuth: true});
}

export function registerSchool (data) {

    return request.post('v2/training/school/register', data);
}
<template>
    <div class="com-dialog-login-share">
        <div class="com-dialog-template index-top">
            <div class="mengban"></div>
            <div class="table">
                <div class="table-cell">
                    <div class="dialog-box">

                        <a class="close" @click="close">×</a>

                        <div class="dialog-content">
                            <p class="dialog-login-title">微信扫码开通功能</p>
                            <div class="dialog-login-box">
                                <div class="com-login-share">
                                    <div id="weixinLogin" class="com-login verify-form com-login-width rel">
                                        <qr-code :codeContent="qrcode" width="200" height="200" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import QrCode from '@/components/widgets/qr-code.vue';

export default defineComponent({

    name: 'login-modal',

    components: {
        QrCode
    },

    props: {

    },

    data: function () {
        return {
            qrcode: (location.host) + '/jiakao/pages/vip'
        };
    },

    methods: {
        close: function () {
            this.$store.commit('app/H5-QRCODE-MODAL', false);
        }
    }
});
</script>

<style>
.index-top {
    z-index: 9999 !important;
}
</style>
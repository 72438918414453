import training, {TState as TTrainingState} from './training';
import app, {TState as TAppState} from './app';
import lang, {TState as TLangState} from './lang';

export interface TState {
    training: TTrainingState;
    app: TAppState;
    lang: TLangState;
}

export default {
    training,
    app,
    lang
};

function zeroFill (num: number | string, len = 2) {

    let str = String(num);

    if (str.length >= len) {
        return str;
    }

    return ('0000000000' + num).slice(-len);
}

export function timeFormat (seconds: number) {

    let minute = Math.floor(seconds / 60);
    let second = seconds % 60;

    return [zeroFill(minute), zeroFill(second)].join(':');
}

export function dateFormat (time: number, format = 'Y-m-d H:i') {

    let date = new Date(time < 9999999999 ? 1000 * time : time);

    let data = {
        Y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        H: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds()
    };

    return format.replace(/Y|m|d|H|i/g, (match) => {
        return zeroFill(data[match]);
    });
}

export function timeSince (time: number) {

    let now = Math.round(Date.now() / 1000);
    let interval = now - time;
    let sufix = interval > 0 ? '前' : '后';

    if (interval < 60) {
        return '1分钟' + sufix;
    }
    else if (interval < 3600) {
        return Math.floor(interval / 60) + '分钟' + sufix;
    }
    else if (interval < 24 * 3600) {
        return Math.floor(interval / 3600) + '小时' + sufix;
    }
    else if (interval < 7 * 24 * 3600) {
        return Math.floor(interval / (24 * 3600)) + '天' + sufix;
    }
    else {
        return dateFormat(time, 'Y-m-d');
    }
}
<template>
    <div class="page-home">
        <div class="">
            <div class="com-home-banner">

                <swiper navigation pagination autoplay loop>
                    <swiper-slide v-for="(item, index) in config.training_pc_banner" :key="index">
                        <a class="a-dis" :href="item.url" :style="{'background-image': 'url(' + item.image + ')'}"></a>
                    </swiper-slide>
                </swiper>

                <div class="menu-container">
                    <div class="banner-menu">
                        <p class="menu-title">学车流程</p>
                        <ul class="menu-list">
                            <li class="icon1">
                                <p class="p1"><a @click="navTo('practice')">顺序练习</a></p>
                                <p class="p2">{{year}}新题库</p>
                            </li>
                            <li class="icon2">
                                <p class="p1"><a @click="navTo('mock')">模拟考试</a></p>
                                <p class="p2">模拟考试真实考试</p>
                            </li>
                            <li class="icon3">
                                <p class="p1"><a @click="navTo('videos')">开车技巧</a></p>
                                <p class="p2">掌握开车技巧安全行驶</p>
                            </li>
                            <li class="icon4">
                                <p class="p1"><a rel="nofollow">新手买车</a></p>
                                <p class="p2">全面汽车资讯和价格</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <exam-menu /> -->

            <rank-list :city_id="city_id" />

            <!-- <ad-banner /> -->

            <school-card-list :city_id="city_id" />

            <training-steps />

            <!-- <div class="com-home-my-honor com-part-home">
                <div class="part-home-title">
                    <hr class="hr">
                    <div class="title-text">宝典荣誉</div>
                    <div class="title-text2">强大的驾考功能，优秀的用户体验，我们的愿景是学车无忧，轻松拿本</div>
                </div>
                <div class="content"><img src="/core-assets/static/images/common/home-dynamic.png"></div>
            </div> -->

            <!-- <div class="com-home-app-ad">
                <div class="content">
                    <div class="ad1"><span>教练APP</span>
                        <div></div>
                    </div>
                    <div class="ad2"><span>驾考APP</span>
                        <div></div>
                    </div>
                    <div class="ad3"><span>买车APP</span>
                        <div></div>
                    </div>
                </div>
            </div> -->

            <!-- <div class="com-home-app-down com-part-home">
                <div class="content">
                    <ul class="item clearfix">
                        <li class="item-left"><span>APP下载</span>
                            <p>考驾照 用宝典</p>
                        </li>
                        <li class="item-left right"><span>微信小程序</span>
                            <p>无需下载，驾考做题更方便！</p>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>

    </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {mapState} from 'vuex';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

import SwiperCore, {Navigation, Pagination, Autoplay} from 'swiper';
import 'swiper/components/navigation/navigation.scss';

import {mergeUrlParams} from '@/utils/url';
// import ExamMenu from '@/components/widgets/exam-menu.vue';
import RankList from '@/components/widgets/rank-list.vue';
import SchoolCardList from '@/components/widgets/school-card-list.vue';
import TrainingSteps from '@/components/widgets/step-list.vue';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default defineComponent({

    components: {
        Swiper,
        SwiperSlide,
        // ExamMenu,
        RankList,
        SchoolCardList,
        TrainingSteps
    },

    data: function () {
        return {
            year: new Date().getFullYear()
        };
    },

    computed: {
        ...mapState('app', ['city_id']),
        ...mapState('training', ['config', 'activeModel', 'activeSubject'])
    },

    methods: {
        navTo: function (type) {

            switch (type) {
                case 'practice':
                    this.$router.push(mergeUrlParams('/exam/practice', {
                        model: this.activeModel,
                        subject: this.activeSubject,
                        pid: '1'
                    }));
                    break;

                case 'mock':
                    this.$router.push(mergeUrlParams('/exam/mock', {
                        model: this.activeModel,
                        subject: this.activeSubject
                    }));
                    break;

                case 'videos':
                    this.$router.push(mergeUrlParams('/videos/index', {
                        model: this.activeModel
                    }));
                    break;
            }
        }
    }
});
</script>

<style>
.a-dis {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
}
</style>
<template>
    <div class="container news-page">
        <div class="award-container result-container">
            <div class="result-box" :class="[pass ? 'cheshen' : 'shashou']">
                <img class="avatar" src="" />
                <h3 class="name">{{$trans(pass ? '我是车神' : '马路杀手')}}</h3>
                <p class="">
                    {{$trans('分数')}}<span class="score">{{mockResult.score}}</span>
                    {{$trans('用时')}}<span class="elapsed">{{timecost}}</span>
                </p>
            </div>
            <div class="result-info">
                <ul class="info-count ">
                    <li><span class="label">{{$trans('答对')}}：</span><a class="nums number-y">{{$trans('%d题', mockResult.correct)}}</a></li>
                    <li><span class="label">{{$trans('答错')}}：</span><a class="nums number-n">{{$trans('%d题', mockResult.error)}}</a></li>
                    <li><span class="label">{{$trans('漏答')}}：</span><a class="nums number-m">{{$trans('%d题', missCount)}}</a></li>
                </ul>

                <div class="info-btn cl">
                    <a class="result-reload" @click="again">{{$trans('再考一次')}}</a>
                    <!-- <a class="result-shear">{{$trans('炫耀')}}</a> -->
                </div>

                <!-- <div class="award-share-item">
                    <div class="arrow arrow-down"></div>
                    <div class="com-share-pannel">
                        <span class="label">分享：</span>
                        <div class="inner">
                            <div class="bdsharebuttonbox bdshare-button-style0-16">
                                <a class="bds_tsina" title="分享到新浪微博"></a>
                                <a class="bds_qzone" title="分享到QQ空间"></a>
                                <a class="bds_weixin" title="分享到微信"></a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {timeFormat} from '@/utils/datetime';
import {mergeUrlParams} from '@/utils/url';

export default defineComponent({

    data: function () {
        let query = this.$route.query;
        let result: string = query.result as string;

        return {
            mockResult: JSON.parse(result)
        };
    },

    computed: {
        timecost: function () {

            return timeFormat(this.mockResult.timecost);
        },

        missCount: function () {

            const result = this.mockResult;

            return result.total - result.correct - result.error;
        },

        pass: function () {

            return this.mockResult.score >= 90;
        }
    },

    methods: {

        again: function () {

            let url = mergeUrlParams('/exam/mock', {
                subject: this.mockResult.subject,
                model: this.mockResult.model
            });

            this.$router.replace(url);
        }
    }
});
</script>
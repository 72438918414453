<template>
    <div class="com-home-study-step com-part-home">
        <div class="part-home-title">
            <hr class="hr">
            <div class="title-text">学车流程</div>
            <div class="title-text2">整合学车全流程，为用户提供全方位学车服务</div>
        </div>
        <div class="content">
            <ul class="item clearfix">
                <li class="item-left icon1">
                    <router-link to="/school">
                        <span class="title">找驾校</span>
                        <p>口碑好的驾校</p>
                    </router-link>
                </li>
                <li class="item-left icon2">
                    <router-link to="/exam">
                        <span class="title">科目一</span>
                        <p>驾驶员理论考试</p>
                    </router-link>
                </li>
                <li class="item-left icon3">
                    <router-link to="/videos?subject=k2">
                        <span class="title">科目二</span>
                        <p>场地驾驶技能考试科目</p>
                    </router-link>
                </li>
                <li class="item-left icon4">
                    <router-link to="/videos?subject=k3">
                        <span class="title">科目三</span>
                        <p>机动车驾驶人道路考试</p>
                    </router-link>
                </li>
                <li class="item-left icon5">
                    <router-link to="/exam">
                        <span class="title">科目四</span>
                        <p>安全文明驾驶常识考试</p>
                    </router-link>
                </li>
                <li class="item-left icon6">
                    <a>
                        <span class="title">拿本</span>
                        <p>开心拿本，安全驾驶</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({

});
</script>
<template>
    <div class="container news-page">

        <bread-crumbs :currentTitle="title" />

        <div class="lx-main jkbd-width wid-auto">

            <h1 class="lx-title">{{$trans(typeTitle)}} {{$trans(title)}}</h1>
            <p class="lx-summary">{{$trans('按照分类逐步练习掌握')}}</p>

            <div class="lx-categroy" v-for="cate in categories" :key="cate.id">
                <div class="categroy-hd ">
                    <h3>
                        <router-link class="hoverBdBlue"
                                :to="{path: 'practice', query: {model: model, subject: subject, cid: cate.id}}">
                            {{cate.title}}
                        </router-link>
                    </h3><!-- <span>（779题）</span> -->
                </div>
                <div class="categroy-bd">
                    <ul class="dot-ul cl">
                        <li>
                            <router-link class="hoverBdBlue"
                                :to="{path: 'practice', query: {model: model, subject: subject, cid: cate.id}}">
                                <span class="dian">•</span>{{$trans('顺序答题')}}
                            </router-link>
                        </li>
                        <li>
                            <router-link class="hoverBdBlue"
                                :to="{path: 'practice', query: {model: model, subject: subject, cid: cate.id, random: 1}}">
                                <span class="dian">•</span>{{$trans('随机答题')}}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';

import {modelText, subjectText} from '@/utils/training';
import {useCategoryList} from '@/compositions/question-category';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';

export default defineComponent({

    components: {
        BreadCrumbs
    },

    props: {
        subject: String,
        model: String,
        pids: String
    },

    setup: function (props) {

        const {subject, model, pids} = toRefs(props);

        return useCategoryList(subject as Ref<string>, model as Ref<string>, pids as Ref<string>);
    },

    computed: {
        typeTitle: function () {
            return modelText(this.model) + subjectText(this.subject);
        },

        title: function () {
            if (this.pids === '2') {
                return '精选题库';
            }
            else {
                return '分类练习';
            }
        }
    }
});
</script>

<style>

.container .lx-main {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px
}

.container .lx-title {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: #3c3c3c;
    height: 40px;
    line-height: 40px
}

.container .lx-summary {
    text-align: center;
    font-size: 14px;
    color: #999;
    height: 26px;
    line-height: 26px
}

.container .lx-categroy {
    padding-top: 23px;
    padding-bottom: 23px;
    border-bottom: 1px solid #f2f2f2
}

.container .lx-categroy.lil {
    border-bottom: 0
}

.container .lx-categroy:last-child {
    border-bottom: none
}

.container .lx-categroy .categroy-hd {
    overflow: hidden;
    height: 38px;
    line-height: 38px
}

.container .lx-categroy .categroy-hd .lx-icon {
    float: left;
    width: 40px;
    height: 24px;
    margin-top: 7px;
    margin-right: 0
}

.container .lx-categroy .categroy-bd {
    padding-left: 10px;
    overflow: hidden;
    color: #999
}

.container .lx-categroy .categroy-bd ul {
    margin-top: 10px
}

.container .lx-categroy .categroy-bd li {
    float: left;
    display: inline;
    height: 36px;
    line-height: 36px;
    padding-left: 13px;
    padding-right: 40px;
    list-style-type: none
}

.container .lx-categroy .categroy-bd li a {
    text-decoration: none;
    color: #666;
    font-size: 16px
}

.container .lx-categroy .categroy-bd li a:hover {
    color: #1dacf9
}

.container .lx-categroy .categroy-bd .dian {
    padding-right: 5px;
    color: #999
}

.container .lx-categroy .categroy-hd h3,
.container .lx-categroy .categroy-hd h3 a {
    font-weight: 700
}

.container .lx-categroy .categroy-hd h3,
.container .lx-categroy .categroy-hd span {
    float: left;
    font-size: 18px;
    color: #4c4c4c;
    display: inline
}

.container .lx-icon {
    display: inline-block
}

.container.isZGZ .lx-categroy .categroy-hd .lx-icon {
    display: none
}

.container.isZGZ .lx-categroy .categroy-bd {
    padding-left: 0
}

</style>
<template>
    <div class="content clearfix">
        <ul class="left-link">
            <li>
                <div class="com-top-user-city clear-cache">
                    <span class="city" @click="showCitySelect = !showCitySelect">
                        <span class="blue2">{{currentCity ? currentCity.n : '请选择'}}</span>
                        &nbsp;&nbsp;[切换]
                    </span>

                    <city-select v-if="showCitySelect" @close="showCitySelect = false" />
                </div>
            </li>
            <li class="fgx"></li>
            <li><a>题库：<span class="blue2">{{activeSubjectText}}</span></a>
                <div class="tiku-mune">
                    <div class="row clearfix">
                        <label class="label left">驾驶证：</label>
                        <ul class="right">
                            <li v-for="item in modelList" :key="item.value"
                                    @click="changeModel(item.value)">
                                <a :class="{'active': item.value === activeModel}">{{item.label}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="row clearfix">
                        <label class="label left">资格证：</label>
                        <ul class="right">
                            <li v-for="item in subjectList" :key="item.value" @click="changeCert(item.value)">
                                <a :class="{'active': item.value === activeSubject}">{{item.label}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li class="fgx"></li>
            <li><a>语言：<span class="blue2">{{langName}}</span></a>
                <div class="tiku-mune">
                    <div class="row clearfix">
                        <label class="label left">语言：</label>
                        <ul class="right">
                            <li v-for="item in langs" :key="item.lang">
                                <a :class="{'active': item.lang === lang}"
                                        @click="changeLang(item.lang)">
                                    {{item.label}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>

        <div class="right-link">
            <router-link class="qiye" rel="nofollow" to="/school/registry">驾校入驻</router-link>
            <a class="qiye" rel="nofollow" href="/admin/login" target="_blank">驾校后台</a>

            <template v-if="isLogin">
                <a>{{userInfo ? userInfo.nickname : ''}}</a>
                <a @click="logout">退出登陆</a>
            </template>
            <a v-else @click="showLoginModal">登录</a>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapState, mapGetters} from 'vuex';

import store, {TState} from '@/store';
import {models, subjects, modelText, subjectText} from '@/utils/training';
import CitySelect from './city-select.vue';

export default defineComponent({

    name: 'top-bar',

    components: {
        CitySelect
    },

    data: function () {
        return {
            showCitySelect: false
        };
    },

    computed: {
        ...mapState<TState>('training', ['activeModel', 'activeSubject']),

        ...mapGetters('app', ['currentCity', 'isLogin']),
        ...mapState('app', ['userInfo']),

        ...mapState<TState>('lang', ['lang', 'langs']),
        ...mapGetters('lang', ['langName']),

        modelList: function () {
            return models.filter((item) => item.value !== 'cert');
        },

        subjectList: function () {
            return subjects.filter((item) => {
                return ['k1', 'k2', 'k3', 'k4'].indexOf(item.value) === -1;
            });
        },

        activeSubjectText: function () {
            if (this.activeModel === 'cert') {
                return subjectText(this.activeSubject);
            }
            else {
                return modelText(this.activeModel);
            }
        }
    },

    methods: {
        changeModel: function (model) {
            store.commit('training/SET-ACTIVE-MODEL', model);
            store.commit('training/SET-ACTIVE-SUBJECT', 'k1');
        },

        changeCert: function (subject) {
            store.commit('training/SET-ACTIVE-MODEL', 'cert');
            store.commit('training/SET-ACTIVE-SUBJECT', subject);
        },

        showLoginModal: function () {
            store.commit('app/LOGIN-MODAL', true);
        },

        changeLang: function (lang) {
            store.dispatch('lang/LANG', lang);
        },

        logout: function () {
            store.commit('app/LOGOUT');
        }
    }
});
</script>
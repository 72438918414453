<template>
    <div class="common-channel-crumb">
        <span>当前位置：</span>
        <a href="/">首页</a>
        <template v-for="(item, index) in path" :key="index">
            <i>&gt;</i><router-link :to="item.path">{{item.title}}</router-link>
        </template>
        <i>&gt;</i><strong>{{title}}</strong>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'bread-crumbs',

    props: {
        currentTitle: String
    },

    data: function () {
        return {
            path: [],
            route: {
                path: this.$route.path,
                title: this.$route.meta.title
            }
        };
    },

    computed: {
        title: function () {
            return this.currentTitle || this.route.title;
        }
    },

    mounted: function () {

        this.path = this.getRoutePath(this.$route);
    },

    methods: {
        getRoutePath: function (route) {
            let matched = route.matched;
            let path = [];

            matched.forEach((item) => {

                let title = typeof item.meta.title === 'string' ? item.meta.title : '';

                if (title && item.path !== route.path) {
                    path.push({
                        path: item.path,
                        title: title,
                    });
                }
            });

            return path;
        }
    }
});
</script>
import {ActionContext} from 'vuex';

import Cache from '@/utils/cache';
import {fetchConfig, getUserAccessList} from '@/api/training';

interface TUserAccess {
    overdue: boolean;
    rule_name: string;
}

interface TFailedQuestion {
    id: number;
    subject: string;
    model: string;
    lang: string;
}

const state = {
    version: '1.0',
    config: {
        baiduApiToken: '',
        training_home_banner: [],
        notification: '',
        wxapp_banner_ad_id: '',
        wxapp_video_ad_id: '',
        training_home_main_nav: [{
            "id": 319,
            "text": "开通VIP",
            "nav_type": "page",
            "url": "/jiakao/pages/vip",
            "query": [],
            "label": "会员"
        }, {
            "id": 317,
            "text": "顺序练习",
            "nav_type": "page",
            "url": "/jiakao/pages/practice",
            "query": ["subject", "model"],
            "label": "会员"
        }, {
            "id": 318,
            "text": "分类练习",
            "nav_type": "page",
            "url": "/jiakao/pages/cates",
            "query": ["subject", "model"],
            "label": "会员"
        }, {
            "id": 320,
            "text": "免费试用",
            "nav_type": "page",
            "url": "/jiakao/pages/free",
            "query": [],
            "label": "免费"
        }, {
            "id": 321,
            "text": "错题收藏",
            "nav_type": "page",
            "url": "/jiakao/pages/failed-questions",
            "query": ["model", "subject"],
            "label": "会员"
        }],
        training_home_second_nav: [
            {
                "text": "模拟考试",
                "nav_type": "page",
                "url": "/jiakao/pages/exam",
                "query": ["subject", "model"],
                "label": "仿真"
            }, {
                "text": "模拟成绩",
                "nav_type": "page",
                "url": "/jiakao/pages/exam-result",
                "query": [],
                "label": "仿真"
            }, {
                "text": "考前须知",
                "nav_type": "page",
                "url": "/pages/news_details/index?id=2",
                "query": [],
                "label": "仿真"
            }
        ],
        free_access: [],
        access_rules: [],
        tengxun_map_key: 'OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77',
        subject_k2_cid: '3',
        subject_k3_cid: '4'
    },
    userAccess: [] as TUserAccess[],
    failedQuestions: Cache.get('failed-questions', []) as TFailedQuestion[], // {id: 1, model: 'cart', subject: 'k1'}

    activeModel: 'cart',
    activeSubject: 'k1'
};

export type TState = typeof state;

type TConfig = typeof state.config;

const getters = {
    isVip: (state: TState) => {

        if (state.userAccess.find((access) => {
            return access.overdue === false;
        })) {
            return true;
        }

        return false;
    },

    trainingAccess: (state: TState) => (ruleName = '') => {
        let accessMap = {};

        let userAccessMap = state.userAccess.reduce((map, item) => {
            map[item.rule_name] = item;

            return map;
        }, {});

        state.config.access_rules.forEach((item) => {

            let free = Number(item.free);
            let rule_name = item.access.indexOf('vip-training-') === 0 ?
                    item.access.replace('vip-training-', '') :
                    item.access;

            if (free) {
                accessMap[item.access] = true;
                accessMap[rule_name] = true;
            }
            else {
                let access = userAccessMap[item.access];

                if (access && !access.overdue) {
                    accessMap[item.access] = true;
                    accessMap[rule_name] = true;
                }
                else {
                    accessMap[item.access] = false;
                    accessMap[rule_name] = false;
                }
            }
        });

        if (ruleName) {
            return Boolean(accessMap[ruleName]);
        }

        return accessMap;
    },

    accessRules: (state) => {

        return state.config.access_rules;
    }
};

const mutations = {

    'UPDATE-CONFIG': (state: TState, config: Partial<TConfig>) => {
        state.config = {
            ...state.config,
            ...config
        };
    },

    'ADD-FAILED-QUESTION': (state: TState, payload: TFailedQuestion) => {
        state.failedQuestions = [
            ...state.failedQuestions,
            payload
        ];
        Cache.set('failed-questions', state.failedQuestions);
    },

    'REMOVE-FAILED-QUESTION': (state: TState, id: number) => {
        state.failedQuestions = state.failedQuestions.filter((q) => {
            return q.id !== id;
        });
        Cache.set('failed-questions', state.failedQuestions);
    },

    'USER-ACCESS': (state: TState, data: TUserAccess[]) => {
        state.userAccess = data;
    },

    'SET-ACTIVE-MODEL': (state: TState, model: string) => {
        state.activeModel = model;
    },

    'SET-ACTIVE-SUBJECT': (state: TState, subject: string) => {
        state.activeSubject = subject;
    },
};

const actions = {

    'FETCH-CONFIG': (context: ActionContext<TState, any>) => {

        return fetchConfig().then((res) => {
            context.commit('UPDATE-CONFIG', res.data);
            return res;
        });
    },

    'LOAD-USER-ACCESS': (context: ActionContext<TState, any>, force = false) => {

        if (force || !context.state.userAccess.length) {
            getUserAccessList().then((res) => {

                context.commit('USER-ACCESS', res.data);
            });
        }
    }
};

export default {

    namespaced: true,

    state,

    getters,

    mutations,

    actions
};
<template>
    <div class="com-dialog-confirm">
        <div class="com-dialog-template">
            <div class="mengban" v-if="mask"></div>
            <div class="table">
                <div class="table-cell">
                    <div class="dialog-box">

                        <!-- <a class="close" @click="$emit('close')">×</a> -->

                        <div class="dialog-content">
                            <div class="dialog-confirm-box">
                                <div class="confirm-context">{{title}}</div>
                                <div class="btn-bar">
                                    <button class="ok" v-if="confirmText" @click="ok">{{$trans(confirmText)}}</button>
                                    <button class="cancel" v-if="cancelText" @click="cancel">{{$trans(cancelText)}}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        title: String,
        confirmText: {
            type: String,
            default: '确定'
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirm: {
            type: Function
        },
        mask: {
            type: Boolean,
            default: true
        },
        autoclose: {
            type: Number,
            default: 0
        }
    },

    mounted: function () {

        if (this.autoclose) {
            setTimeout(() => {
                this.cancel();
            }, this.autoclose);
        }
    },

    methods: {
        ok: function () {

            if (typeof this.confirm === 'function') {
                this.confirm(true);
            }

            this.$emit('confirm', true);
        },

        cancel: function () {

            if (typeof this.confirm === 'function') {
                this.confirm(false);
            }

            this.$emit('confirm', false);
        }
    }
});
</script>

<style>
.dialog-box {
    box-shadow: 0px 0px 5px #555;
}
</style>

import {defineComponent, toRefs, ref, Ref, computed} from 'vue';
import {mapGetters} from 'vuex';

import {abcIndex, subjectText} from '@/utils/training';
import {timeFormat} from '@/utils/datetime';
import {logExamResult} from '@/api/training';
import {replaceMark} from '@/utils/training';

import {
    useAnswerSheet, useQuestionList, useExamQuestions,
    useQuestionDetail, usePaperCountDown
} from '@/compositions/question-paper';

export default defineComponent({
    name: 'mock-paper',

    components: {

    },

    props: {
        model: String,
        subject: String
    },

    setup: function (props) {
        const {model, subject} = toRefs(props);
        const {questions} = useExamQuestions(model as Ref<string>, subject as Ref<string>);

        const listSetup = useQuestionList(questions);

        const paperId = ref('');

        const answerSheetSetup = useAnswerSheet(paperId, listSetup.current, listSetup.question);

        const quetionDetailSetup = useQuestionDetail(listSetup.question, answerSheetSetup.currentAnswer);

        const countDownSetup = usePaperCountDown(props.subject as string);

        return {
            questions,
            ...listSetup,
            ...answerSheetSetup,
            ...quetionDetailSetup,
            ...countDownSetup
        };
    },

    data: function () {

        return {
            failed: false
        };
    },

    mounted: function () {

        this.$store.dispatch('app/SHOW-CONFIRM', {
            title: this.$trans('模拟考试考试下不能修改答案，每做一题，系统自动计算错误题数，及格标准为90分。'),
            cancelText: '',
            confirm: () => {
                this.startCountDown();
            }
        });
    },

    computed: {
        ...mapGetters('lang', ['langDirection']),
        answerABC: function () {
            if (this.question) {
                if (this.question.type === 1) {
                    let answer = this.question.answer === 1 ? 1 : 2;
                    return this.abcIndex(answer, true);
                }
                else {
                    return this.abcIndex(this.question.answer, true);
                }
            }

            return '';
        },

        countDownText: function () {

            return timeFormat(this.totalTime - this.timecost);
        },

        subjectText: function () {

            return subjectText(this.subject);
        },

        titleHTML: function () {

            return [this.current + 1, '、', replaceMark(this.question.title)].join('');
        }
    },

    watch: {
        timecost: function () {
            if (this.timecost >= this.totalTime) {
                this.timeup();
            }
        },

        failedCount: function () {
            let total = this.questions.length;
            let scorePerQuestion = 100 / total;
            let lostScore = this.failedCount * scorePerQuestion;

            if (lostScore > 10) {
                this.fail();
            }
        }
    },

    methods: {

        replaceMark: replaceMark,

        abcIndex: function (index, answer = false) {

            return abcIndex(index, answer);
        },

        toggleExplain: function () {
            this.showExplain = !this.showExplain;
        },

        toggleList: function () {
            this.showList = !this.showList;
        },

        confirmAnswer: function () {

            let answer = this.selected;
            let correct;

            if (this.question.type === 1) {
                answer = answer === 1 ? 1 : 0; // 判断题第一个选项正确，第二个选项错误
            }

            correct = this.question.answer === answer;

            if (correct) {
                if (this.failed) {
                    this.$store.commit('training/REMOVE-FAILED-QUESTION', this.question.id);
                }
            }
            else {
                if (!this.free) {
                    this.$store.commit('training/ADD-FAILED-QUESTION', {
                        id: this.question.id,
                        subject: this.subject,
                        model: this.model,
                        lang: this.$store.state.lang.lang
                    });
                }
            }

            let userAnswer = {
                id: this.question.id,
                answer: this.selected,
                correct: correct
            };

            this.addUserAnswer(userAnswer);

            setTimeout(() => {
                this.next();
            }, 200);
        },

        confirmMultiAnswer: function () {
            if (this.selected) {
                this.confirmAnswer();
            }
        },

        select: function (index) {
            if (this.selectOption(index)) {
                if (!this.isMultiAnswer) {
                    this.confirmAnswer();
                }
            }
        },

        handIn: function () {

            this.$store.dispatch('app/SHOW-CONFIRM', {
                title: this.$trans('确认要交卷吗？'),
                confirm: (ok) => {
                    if (ok) {
                        this.showResult();
                    }
                }
            });
        },

        showResult: function () {

            let total = this.questions.length;
            let scorePerQuestion = 100 / total;

            if (scorePerQuestion !== 1 && scorePerQuestion !== 2) {
                if (this.model === 'mtc') {
                    scorePerQuestion = 2;
                }
                else if (this.subject === 'k4') {
                    scorePerQuestion = 2;
                }
                else {
                    scorePerQuestion = 1;
                }
            }

            let result = {
                subject: this.subject,
                model: this.model,
                timecost: this.timecost,
                timetotal: this.totalTime,
                score: this.correctCount * scorePerQuestion,
                total_score: total * scorePerQuestion,
                correct: this.correctCount,
                total: total,
                error: this.failedCount,
                lang: this.$store.state.lang.lang
            };

            if (result.score) {
                logExamResult(result);
            }

            this.stopCountDown();
            this.$router.replace('/exam/award?result=' + encodeURIComponent(JSON.stringify(result)));
        },

        showExplain: function () {
            if (this.question.explain_js) {
                this.$store.dispatch('app/SHOW-CONFIRM', {
                    title: this.question.explain_js,
                    cancelText: ''
                });
            }
        },

        timeup: function () {
            this.$store.dispatch('app/SHOW-CONFIRM', {
                title: this.$trans('考试时间结束，自动交卷'),
                cancelText: '',
                confirm: () => {
                    this.showResult();
                }
            });
        },

        fail: function () {

            if (!this.failed) {
                this.failed = true;

                this.$store.dispatch('app/SHOW-CONFIRM', {
                    title: this.$trans('答错 %d 题，考试失败, 是否交卷？', this.failedCount),
                    confirmText: this.$trans('交卷'),
                    cancelText: this.$trans('继续答题'),
                    confirm: (ok) => {
                        if (ok) {
                            this.showResult();
                        }
                    }
                });
            }
        }
    }
});

import { ref, onMounted, onUnmounted, computed, watch, Ref } from 'vue';
import {fetchPracticeQuestions, fetchExamQuestions} from '@/api/training';
import cache from '@/utils/cache';
import store from '@/store';

const $trans = store.getters['lang/trans'];

export function usePracticeQuestions (
        model: Ref<string>,
        subject: Ref<string>,
        cid: Ref<string>,
        random?: Ref<boolean>,
        pid?: Ref<number>
    ) {

    const questions = ref([]);
    const getQuestions = async () => {
        const res = await fetchPracticeQuestions({
            model: model.value,
            subject: subject.value,
            cid: cid.value,
            pid: pid && pid.value
        });

        let qs;

        if (random && random.value) {
            qs = res.data.sort(() => 0.5 - Math.random());
        }
        else {
            qs = res.data;
        }

        questions.value = qs;
    };

    onMounted(getQuestions);

    let watchProps: Ref<any>[] = [model, subject, cid];

    if (random) {
        watchProps.push(random);
    }

    watch(watchProps, getQuestions);

    return {
        questions
    };
}

export function useExamQuestions (model: Ref<string>, subject: Ref<string>) {

    const questions = ref([]);
    const getQuestions = async () => {
        questions.value = await fetchExamQuestions({
            model: model.value,
            subject: subject.value
        }).then((res) => {
            return res.data;
        });
    };

    onMounted(getQuestions);
    watch([model, subject], getQuestions);

    return {
        questions
    };
}

export function useQuestionList (questions: Ref<any[]>) {
    let current = ref(0);
    let question = computed(() => {
        return questions.value[current.value];
    });

    return {
        current,
        question,
        prev: () => {
            if (current.value > 0) {
                current.value--;
            }
        },
        next: () => {
            if (current.value < questions.value.length - 1) {
                current.value++;
            }
        }
    };
}

export function useQuestionDetail (question: Ref<any>, userAnswer: Ref<any>) {

    let selected = ref(0);

    let answerWrong = computed(() => {
        let q = question.value;

        if (q) {
            let answer = userAnswer.value ? userAnswer.value.answer : 0;

            // 判断题的答案为 1，0 不是 1，2
            if (q.type === 1) {
                answer = answer === 1 ? 1 : 0;
            }

            if (answer && answer !== q.answer) {
                return true;
            }
        }

        return false;
    });

    let options = computed(() => {
        let q = question.value;

        if (q) {
            let answer = userAnswer.value ? userAnswer.value.answer : 0;
            let options = [];

            if (q.type === 1) {
                options = [
                    {option: $trans('正确'), correct: q.answer === 1},
                    {option: $trans('错误'), correct: q.answer === 0}
                ];
            }
            else {
                options = JSON.parse(q.opts);
            }

            options.forEach((item, index) => {
                item.selected = Boolean(answer & (1 << index));
            });

            return options;
        }
        else {
            return [];
        }
    });

    const isMultiAnswer = computed(() => {
        let q = question.value;

        return q ? q.type === 3 : false;
    });

    const selectOption = (index: number) => {

        if (!userAnswer.value) {
            selected.value = selected.value | (1 << index);
            return true;
        }

        return false;
    };

    const updateSelected = () => {

        let answer = userAnswer.value ? userAnswer.value.answer : 0;

        selected.value = answer;
    };

    onMounted(updateSelected);
    watch([question, userAnswer], updateSelected);

    return {
        options,

        answerWrong,

        isMultiAnswer,

        selectOption,

        selected
    };
}

export function useAnswerSheet (paperId: Ref<string>, current: Ref<number>, question: Ref<any>) {

    const userAnswers = ref({});

    const resetProgress = () => {
        if (paperId.value) {
            cache.clear('practice-progress-' + paperId.value);
        }
    };

    const loadPaperProgress = () => {
        const prog = cache.get('practice-progress-' + paperId.value);

        if (prog) {

            store.dispatch('app/SHOW-CONFIRM', {
                title: $trans('上次练习到第 %d 题, 是否接着上次进度继续练习？', prog.current),
                confirm: (confirmed) => {
                    if (confirmed) {
                        current.value = prog.current;
                        userAnswers.value = prog.userAnswers;
                    }
                    else {
                        resetProgress();
                    }
                }
            });
        }
    };

    const logProgress = () => {

        if (paperId.value) {
            cache.set('practice-progress-' + paperId.value, {
                current: current.value,
                userAnswers: JSON.parse(JSON.stringify(userAnswers.value))
            });
        }
    };

    const addUserAnswer = (userAnswer: {id: number; answer: number; correct: boolean;}) => {
        userAnswers.value = {
            ...userAnswers.value,
            [userAnswer.id]: userAnswer
        };
    };

    const correctCount = computed(() => {
        const answers = userAnswers.value;

        return Object.keys(userAnswers.value).reduce((count, id) => {
            if (answers[id].correct) {
                return count + 1;
            }

            return count;
        }, 0);
    });

    const failedCount = computed(() => {
        const answers = userAnswers.value;

        return Object.keys(userAnswers.value).reduce((count, id) => {
            if (!answers[id].correct) {
                return count + 1;
            }

            return count;
        }, 0);
    });

    const correctRatio = computed(() => {
        const total = Object.keys(userAnswers.value).length;

        if (!total) {
            return '0.00';
        }

        return (correctCount.value / total * 100).toFixed(2);
    });

    const currentAnswer = computed(() => {
        const q = question.value;

        if (q) {
            return userAnswers.value[q.id] || null;
        }

        return null;
    });

    if (paperId.value) {
        onMounted(loadPaperProgress);
        watch(paperId, loadPaperProgress);
        watch(current, logProgress);
    }

    return {
        userAnswers,
        correctCount,
        failedCount,
        correctRatio,
        currentAnswer,
        addUserAnswer
    };
}

export function usePaperCountDown (subject: string) {

    let timer = 0;
    const timecost = ref(0);
    let totalTime = ref(0);

    if (subject !== 'k1' && subject !== 'k4') {
        totalTime.value = 60 * 60;
    }
    else {
        totalTime.value = 45 * 60;
    }

    const startCountDown = () => {
        timer = setInterval(() => {
            if (timecost.value >= totalTime.value) {
                this.setTimeup();
                this.clearCountDown();
            }
            else {
                timecost.value++;
            }
        }, 1000);

        window.onbeforeunload = () => {
            return '确定放弃考试进度？';
        };
    };

    const timeup = () => {
        stopCountDown();
    };

    const stopCountDown = () => {
        if (timer) {
            clearInterval(timer);
            timer = 0;
        }

        window.onbeforeunload = null;
    };

    onUnmounted(stopCountDown);

    return {
        totalTime,
        timecost,
        startCountDown,
        stopCountDown
    };
}
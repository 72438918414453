import { createRouter, createWebHistory } from 'vue-router';

import routes from './routes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active'
});

router.beforeEach(to => {
    // return false;
});

router.afterEach(to => {
    // 返回页面顶端
    window.scrollTo(0, 0);

    if (typeof to.meta.title === 'string') {
        document.title = to.meta.title;
    }
})

export default router;


import {defineComponent} from 'vue';
import {replaceMark} from '@/utils/training';

export default defineComponent({

    props: {
        option: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        showAnswer: Boolean,
        answerWrong: Boolean, // 回答错误
        // answer: Number,
        userAnswer: {
            type: Number,
            default: 0
        }
    },

    setup: function (props) {
        return {};
    },

    computed: {

        selected: function () {

            return (this.userAnswer & (1 << this.index)) !== 0;
        },

        isCorrectOption: function () {

            return this.option.correct;
        },

        optionClassNames: function () {
            let classNames = [];

            if (this.showAnswer) {
                if (this.selected) {
                    if (this.isCorrectOption) {
                        classNames.push('success');
                    }
                    else {
                        classNames.push('error');
                    }
                }
                else if (this.isCorrectOption) {
                    classNames.push('success');
                    classNames.push('bug');
                }
            }
            else {
                if (this.selected) {
                    classNames.push('success');
                }
            }

            return classNames;
        },

        optionHtml: function () {

            return this.abcIndex(this.index) + '. ' + this.replaceMark(this.option.option);
        }
    },

    methods: {

        abcIndex: function (index) {
            return 'ABCDEF'[index];
        },

        select: function (index) {
            this.$emit('select-option', index);
        },

        replaceMark
    }
});

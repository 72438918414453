
import { defineComponent } from 'vue';
import {mapState} from 'vuex';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

import SwiperCore, {Navigation, Pagination, Autoplay} from 'swiper';
import 'swiper/components/navigation/navigation.scss';

import {mergeUrlParams} from '@/utils/url';
// import ExamMenu from '@/components/widgets/exam-menu.vue';
import RankList from '@/components/widgets/rank-list.vue';
import SchoolCardList from '@/components/widgets/school-card-list.vue';
import TrainingSteps from '@/components/widgets/step-list.vue';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default defineComponent({

    components: {
        Swiper,
        SwiperSlide,
        // ExamMenu,
        RankList,
        SchoolCardList,
        TrainingSteps
    },

    data: function () {
        return {
            year: new Date().getFullYear()
        };
    },

    computed: {
        ...mapState('app', ['city_id']),
        ...mapState('training', ['config', 'activeModel', 'activeSubject'])
    },

    methods: {
        navTo: function (type) {

            switch (type) {
                case 'practice':
                    this.$router.push(mergeUrlParams('/exam/practice', {
                        model: this.activeModel,
                        subject: this.activeSubject,
                        pid: '1'
                    }));
                    break;

                case 'mock':
                    this.$router.push(mergeUrlParams('/exam/mock', {
                        model: this.activeModel,
                        subject: this.activeSubject
                    }));
                    break;

                case 'videos':
                    this.$router.push(mergeUrlParams('/videos/index', {
                        model: this.activeModel
                    }));
                    break;
            }
        }
    }
});

import { ref, onMounted, onUnmounted, computed, watch, Ref } from 'vue';
import {fetchCateList} from '@/api/training';

export function useCategoryList (subject: Ref<string>, model: Ref<string>, pids: Ref<string>) {

    const categories = ref([]);

    const getCategoryList = async () => {

        const res = await fetchCateList({
            model: model.value,
            subject: subject.value,
            pids: pids.value
        });

        categories.value = res.data;
    };

    onMounted(getCategoryList);
    watch([model, subject, pids], getCategoryList);

    return {
        categories
    };
}
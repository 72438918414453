
import {defineComponent} from 'vue';
import {loginH5, loginMobile, registerVerify, register, getCodeApi,
    getLoginQrcode, waitQrcodeLogin
} from "@/api/user";
import {getLogo} from "@/api/public";
import cache from '@/utils/cache';
import QrCode from '@/components/widgets/qr-code.vue';

import WxLogin from '@/utils/wx-login';
import {polling} from '@/utils/request';

export default defineComponent({

    name: 'login-modal',

    components: {
        QrCode
    },

    props: {

    },

    data: function () {
        return {
            navList: ["快速登录", "账号登录"],
            account: "",
            password: "",
            captcha: "",
            formItem: 1,
            type: "login",
            logoUrl: "",
            keyCode: "",
            codeUrl: "",
            codeVal: "",
            isShowCode: false,
            agreeProtocol: false,
            loginType: 'account',
            qrcode: ''
        };
    },

    mounted: function () {

        this.getCode();
        this.getLogoImage();
    },

    beforeUnmount: function () {
        if (this.stopPolling) {
            this.stopPolling();
            this.stopPolling = null;
        }
    },

    methods: {
        close: function () {
            this.$store.commit('app/LOGIN-MODAL', false);
        },

        changeLoginType: function (type) {
            this.loginType = type;

            if (type === 'weixin') {
                // this.$nextTick(() => {
                //     WxLogin({
                //         id: 'weixinLogin',
                //         appid: 'wx9f9354e11ed450de',
                //         scope: 'snsapi_login',
                //         redirect_uri: encodeURIComponent(window.top.location.href)
                //     });
                // });

                if (this.stopPolling) {
                    this.stopPolling();
                    this.stopPolling = null;
                }

                getLoginQrcode().then((res) => {

                    if (res.data.qrcode_url) {
                        this.qrcode = res.data.qrcode_url;

                        this.stopPolling = polling(waitQrcodeLogin, {ticket: res.data.ticket}, (res) => {
                            if (res.data && res.data.token) {
                                this.$store.commit('app/LOGIN', {
                                    'token': res.data.token,
                                    'time': res.data.expires_time - cache.time()
                                });
                                this.$store.dispatch('app/USERINFO');
                                this.close();
                                return false;
                            }
                        });
                    }
                });
            }
        },

        getCode() {
            getCodeApi()
                .then(res => {
                    this.keyCode = res.data.key;
                })
                .catch(res => {
                    this.$util.Tips({
                        title: res
                    });
                });
        },
        async getLogoImage() {
            getLogo().then(res => {
                this.logoUrl = res.data.logo_url;
            });
        },
        async loginMobile() {
            if (!this.account) return this.$util.Tips({
                title: '请填写手机号码'
            });
            if (!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(this.account)) return this.$util.Tips({
                title: '请输入正确的手机号码'
            });
            if (!this.captcha) return this.$util.Tips({
                title: '请填写验证码'
            });
            if (!/^[\w\d]+$/i.test(this.captcha)) return this.$util.Tips({
                title: '请输入正确的验证码'
            });
            loginMobile({
                phone: this.account,
                captcha: this.captcha,
                spread: cache.get("spread")
            })
            .then(res => {
                let data = res.data;
                this.$store.commit('app/LOGIN', {
                    'token': data.token,
                    'time': data.expires_time - cache.time()
                });

                this.$store.dispatch('app/USERINFO');
            })
            .catch(res => {
                this.$util.Tips({
                    title: res
                });
            });
        },
        async register() {
            if (!this.account) return this.$util.Tips({
                title: '请填写手机号码'
            });
            if (!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(this.account)) return this.$util.Tips({
                title: '请输入正确的手机号码'
            });
            if (!this.captcha) return this.$util.Tips({
                title: '请填写验证码'
            });
            if (!/^[\w\d]+$/i.test(this.captcha)) return this.$util.Tips({
                title: '请输入正确的验证码'
            });
            if (!this.password) return this.$util.Tips({
                title: '请填写密码'
            });
            if (/^([0-9]|[a-z]|[A-Z]){0,6}$/i.test(this.password)) return this.$util.Tips({
                title: '您输入的密码过于简单'
            });
            register({
                    account: this.account,
                    captcha: this.captcha,
                    password: this.password,
                    spread: cache.get("spread")
                })
                .then(res => {
                    this.$util.Tips({
                        title: res
                    });
                    this.formItem = 1;
                })
                .catch(res => {
                    this.$util.Tips({
                        title: res
                    });
                });
        },
        async code() {
            if (!this.account) return this.$util.Tips({
                title: '请填写手机号码'
            });
            if (!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(this.account)) return this.$util.Tips({
                title: '请输入正确的手机号码'
            });
            if (this.formItem == 2) this.type = "register";

            await registerVerify({
                phone: this.account,
                type: this.type,
                key: this.keyCode,
                code: this.codeVal
            })
            .then(res => {
                this.$util.Tips({
                    title: res.msg
                });
                this.sendCode();
            })
            .catch(res => {
                console.log(res, 'res')
                // if (res.data.status === 402) {
                //  this.codeUrl = `${VUE_APP_API_URL}/sms_captcha?key=${this.keyCode}`;
                //  this.isShowCode = true;
                // }
                this.$util.Tips({
                    title: res
                });
            });
        },

        async submit() {

            if (!this.account) return this.$util.Tips({
                title: '请填写账号'
            });

            if (!/^[\w\d]{5,16}$/i.test(this.account)) return this.$util.Tips({
                title: '请输入正确的账号'
            });

            if (!this.password) return this.$util.Tips({
                title: '请填写密码'
            });

            loginH5({
                account: this.account,
                password: this.password,
                spread: cache.get("spread")
            })
            .then(({
                data
            }) => {
                console.log(cache.time());
                this.$store.commit('app/LOGIN', {
                    'token': data.token,
                    'time': data.expires_time - cache.time()
                });
                this.$store.dispatch('app/USERINFO');

                this.close();
            })
            .catch(e => {
                this.$util.Tips({
                    title: e
                });
            });
        }
    }
});

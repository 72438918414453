
import {defineComponent, toRefs, Ref, computed} from 'vue';
import {mapGetters} from 'vuex';

import {fetchPracticeQuestions} from '@/api/training';
import ExplainPanel from './explain-panel.vue';
import QuestionDetail from './question-detail.vue';
import {useAnswerSheet, useQuestionList, usePracticeQuestions} from '@/compositions/question-paper';

export default defineComponent({
    name: 'practice-paper',

    components: {
        ExplainPanel,
        QuestionDetail
    },

    props: {
        model: String,
        subject: String,
        cid: String,
        random: Boolean,
        pid: Number
    },

    setup: function (props) {
        const {model, subject, cid, random, pid} = toRefs(props);
        const {questions} = usePracticeQuestions(
            model as Ref<string>,
            subject as Ref<string>,
            cid as Ref<string>,
            random as Ref<boolean>,
            pid as Ref<number>
        );

        const listSetup = useQuestionList(questions);

        const paperId = computed(() => {
            return [cid.value, model.value, subject.value].join('-');
        });

        const answerSheetSetup = useAnswerSheet(paperId, listSetup.current, listSetup.question);

        return {
            questions,
            ...listSetup,
            ...answerSheetSetup
        };
    },

    data: function () {
        let query = this.$route.query;

        return {
            showExplain: false,
            showList: false,
            autoNext: true
        };
    },

    computed: {
        ...mapGetters('lang', ['langDirection'])
    },

    mounted: function () {

        this.$store.dispatch('training/LOAD-USER-ACCESS');
    },

    methods: {

        toggleExplain: function () {
            this.showExplain = !this.showExplain;
        },

        toggleList: function () {
            this.showList = !this.showList;
        },

        confirmAnswer: function (userAnswer) {
            this.addUserAnswer(userAnswer);

            if (userAnswer.correct && this.autoNext) {
                setTimeout(() => {
                    this.next();
                }, 500);
            }
        }
    }
});

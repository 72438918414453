<template>
    <div class="common-city-selection-dialog show">

        <span class="arrow left">
            <span class="arrow"></span>
        </span>

        <span class="close" @click="close"></span>

        <div class="content">
            <div class="common-city-selection">
                <!-- <div class="hot clearfix"><span>热门城市:</span>
                    <ul>
                        <li class="quanguo">全国</li>
                    </ul>
                </div> -->
                <div class="index">
                    <ul>
                        <li v-for="item in alphaSortCitys" :key="item.i"
                                @click="toAlpha(item.i)">
                            {{item.i}}
                        </li>
                    </ul>
                </div>
                <div class="list" ref="scrollContainer">
                    <div class="municipality">
                        <ul>
                            <li class="title">直辖市：</li>
                            <li v-for="item in specialCitys" :key="item.v"
                                    @click="selectCity(item.c[0])">
                                {{item.n}}
                            </li>
                        </ul>
                    </div>
                    <ul class="clearfix">
                        <li v-for="item in alphaSortCitys" :key="item.i" :ref="item.i">
                            <i>{{item.i}}</i>
                            <div class="item" v-for="province in item.c" :key="province.v">
                                <span>{{province.n}}：</span>
                                <ul>
                                    <li v-for="city in province.c" :key="city.v"
                                            @click="selectCity(city)">
                                        {{city.n}}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapState} from 'vuex';

export default defineComponent({

    computed: {
        ...mapState('app', ['cityData']),

        alphaSortCitys: function () {
            const cityData = this.cityData;
            const citys: any[] = [];
            const cityMap = {};

            cityData.forEach((p) => {
                let iCitys = cityMap[p.i];

                if (!iCitys) {
                    iCitys = cityMap[p.i] = {
                        i: p.i,
                        c: [p]
                    };

                    citys.push(iCitys);
                }
                else {
                    iCitys.c.push(p);
                }
            });

            return citys.sort((a, b) => {
                return a.i.charCodeAt() - b.i.charCodeAt();
            });
        },

        specialCitys: function () {

            return this.cityData.filter((p) => {
                return p.c && p.c.length === 1 && p.n === p.c[0].n;
            });
        }
    },

    methods: {
        selectCity: function (city) {
            this.$store.commit('app/CITY-ID', city.v);

            this.close();
        },

        close: function () {

            this.$emit('close');
        },

        toAlpha: function (alpha) {
            let el = this.$refs[alpha];
            let scrollContainer = this.$refs.scrollContainer;

            scrollContainer.scrollTop = el.offsetTop;
        }
    }
});
</script>
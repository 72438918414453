import {HTTP_REQUEST_URL} from '@/config/app';

export function fullUrl (path, prefix) {

    if (/^[a-z]+:\/\//.test(path)) {
        return path;
    }
    else if (path.indexOf('//') === 0) {
        return 'https:' + path;
    }
    else if (path[0] === '/') {
        return HTTP_REQUEST_URL + path;
    }
    else {
        return HTTP_REQUEST_URL + (prefix ? '/' + prefix + '/' : '/') + path;
    }
}

let globalAudio: HTMLAudioElement;

export const audioStop = () => {

    if (globalAudio) {
        globalAudio.pause();
        globalAudio.src = null;
    }
};

export const audioPlay = function (src, play, standAlone = false) {

    let audio = document.createElement('audio');

    if (standAlone) {
        //
    }
    else {
        if (globalAudio) {
            globalAudio.pause();
            globalAudio.src = null;
        }

        globalAudio = audio;
    }

    audio.autoplay = false;

    audio.onerror = (err) => {
        console.log('audio error', err);
    };

    audio.oncanplay = () => {
        console.log('canPlay', audio.src);
    };

    if (src) {
        audio.src = src;
    }

    if (play) {
        audio.autoplay = true;
        audio.play();
    }

    return audio;
};
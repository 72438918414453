import { ref, onMounted, onUnmounted, computed, watch, Ref, reactive } from 'vue';
import {
    getSchoolList, getSchoolInfo, getClassList, getCoachList,
    getSchoolRank, getCoachRank
} from '@/api/training';

import store from '@/store';

export function useSchoolListFilter (page: Ref<number>) {
    const filter = reactive({
        area_id: 0,
        type: '',
        charge: '',
        orderby: '',
        desc: 1
    });

    watch([filter], () => {
        console.log('filter change');
        page.value = 1;
    });

    return filter;
}

export function useSchoolList (page: Ref<number>, filter) {

    const schools = ref([]);
    const total = ref(0);
    const city_id = ref(store.state.app.city_id);

    const removeWatcher = store.watch((state) => {
        return state.app.city_id;
    }, () => {
        city_id.value = store.state.app.city_id;
    });

    onUnmounted(removeWatcher);

    const fetchSchooList = async () => {

        const res = await getSchoolList({
            page: page.value,
            city: city_id.value,
            ...filter
        });

        schools.value = res.data.list;
        total.value = res.data.count;
    };

    watch(city_id, () => {
        filter.area_id = 0;
    });

    onMounted(fetchSchooList);
    watch([city_id, page, filter], fetchSchooList);

    return {
        schools,
        total,
        fetchSchooList
    };
}

export function useSchoolDetail (id: Ref<number>) {

    const school = ref({});

    const getSchoolDetail = async () => {

        const res = await getSchoolInfo(id.value);

        school.value = res.data;
    };

    onMounted(getSchoolDetail);
    watch([id], getSchoolDetail);

    return {
        school
    };
}

export function useTrainingClassList (sid: Ref<number>) {

    const classList = ref([]);

    const fetchClassList = async () => {

        const res = await getClassList({
            sid: sid.value
        });

        classList.value = res.data;
    };

    onMounted(fetchClassList);
    watch([sid], fetchClassList);

    return {
        classList
    };
}

export function useCoachList (sid: Ref<number>) {

    const coachList = ref([]);

    const fetchCoachList = async () => {

        const res = await getCoachList({
            sid: sid.value
        });

        coachList.value = res.data;
    };

    onMounted(fetchCoachList);
    watch([sid], fetchCoachList);

    return {
        coachList
    };
}

export function useSchoolRank (type: string, limit: number, city_id: Ref<number>) {

    const schools = ref([]);

    const fetchSchooList = async () => {

        const res = await getSchoolRank({
            city: city_id.value,
            type: type,
            limit: limit
        });

        schools.value = res.data.list;
    };

    onMounted(fetchSchooList);
    watch(city_id, fetchSchooList);

    return {
        schools
    };
}

export function useCoachRank (type: string, limit: number, city_id: Ref<number>) {

    const coachList = ref([]);

    const fetchCoachList = async () => {

        const res = await getCoachRank({
            city: city_id.value,
            type: type,
            limit: limit
        });

        coachList.value = res.data;
    };

    onMounted(fetchCoachList);
    watch([city_id], fetchCoachList);

    return {
        coachList
    };
}

import {defineComponent} from 'vue';
import {mapState} from 'vuex';

export default defineComponent({
    name: 'main-nav',

    data: function () {
        return {
            navs: [
                {
                    path: '/',
                    title: '首页'
                },
                {
                    path: '/exam',
                    title: '模拟考试'
                },
                {
                    path: '/videos',
                    title: '视频学车',
                    children: [
                        {
                            path: '/videos/index?subject=k2',
                            title: '科目二'
                        },
                        {
                            path: '/videos/index?subject=k3',
                            title: '科目三'
                        }
                    ]
                },
                {
                    path: '/school',
                    title: '找驾校'
                },
                {
                    path: '/bbs',
                    title: '驾考圈'
                },
                {
                    path: '/signs',
                    title: '交通标志'
                }
            ]
        };
    },

    computed: {
        ...mapState('training', ['config'])
    }
});

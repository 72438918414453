
import {defineComponent} from 'vue';
import Qrcode from 'qrcodejs2';

export default defineComponent({

    props: {
        codeContent: {
            type: String,
            default: ''
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        }
    },

    data: function () {
        return {

        };
    },

    watch: {
        codeContent: function () {
            this.bindQRCode();
        }
    },

    mounted: function () {
        this.bindQRCode();
    },

    methods: {
        bindQRCode: function () {
            this.$refs.qrcode.innerHTML = '';
            new Qrcode(this.$refs.qrcode, {
                text: this.codeContent,
                width: this.width,
                height: this.height,
                colorDard: '#333333',
                colorLight: '#ffffff',
                correctLevel: Qrcode.CorrectLevel.L
            });
        }
    }
});

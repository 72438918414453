<template>
    <div class="container news-page">
        <mock-paper :model="model" :subject="subject" />
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import MockPaper from '@/components/exam/mock-paper.vue';

export default defineComponent({
    name: 'mock-exam',

    components: {
        MockPaper
    },

    data: function () {

        return {
            model: '',
            subject: '',
            ...this.$route.query
        };
    }
});
</script>
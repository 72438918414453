<template>

    <div :style="{direction: langDirection}" :direction="langDirection">
        <question-detail v-if="question"
                :current="current"
                :question="question"
                :total="questions.length"
                :userAnswer="currentAnswer"
                @confirm-answer="confirmAnswer" />

        <div class=" com-mnks-datika">

            <div class="tool-bar">
                <div class="btn-bar clearfix">
                    <button class="left gl" @click="prev">{{$trans('上一题')}}</button>
                    <button class="left gl" @click="next">{{$trans('下一题')}}</button>
                    <button class="right pt" @click="toggleList">{{$trans(showList ? '收起答题卡' : '展开答题卡')}}</button>
                    <button class="right pt" @click="toggleExplain">{{$trans(showExplain ? '收起详解' : '展开详解')}}</button>
                </div>

                <div class="msg-bar clearfix">
                    <label class="daduinext left">
                        <input type="checkbox" class="checkbox-next" v-model="autoNext" /><span>&nbsp;{{$trans('答对自动下一题')}}</span>
                    </label>
                    <span class="left"><span class="gray">{{$trans('答对')}}：</span><span class="count-right">{{$trans('%d题', correctCount)}}</span></span>
                    <span class="left"><span class="gray">{{$trans('答错')}}：</span><span class="count-wrong">{{$trans('%d题', failedCount)}}</span></span>
                    <span class="left"><span class="gray">{{$trans('正确率')}}：</span>{{correctRatio}}%</span>
                </div>
            </div>

            <ul class="list-w clearfix show" v-show="showList">
                <li v-for="(question, index) in questions" :key="question.id">
                    <a :class="{'right': userAnswers[question.id] && userAnswers[question.id].correct, 'error': userAnswers[question.id] && !userAnswers[question.id].correct, 'curr': index === current}"
                            @click="current = index">
                        {{index + 1}}
                    </a>
                </li>
            </ul>

        </div>

        <div v-show="showExplain">
            <explain-panel :model="model" :subject="subject" :question="question" v-if="question" />
        </div>

    </div>
</template>

<script lang="ts">
import {defineComponent, toRefs, Ref, computed} from 'vue';
import {mapGetters} from 'vuex';

import {fetchPracticeQuestions} from '@/api/training';
import ExplainPanel from './explain-panel.vue';
import QuestionDetail from './question-detail.vue';
import {useAnswerSheet, useQuestionList, usePracticeQuestions} from '@/compositions/question-paper';

export default defineComponent({
    name: 'practice-paper',

    components: {
        ExplainPanel,
        QuestionDetail
    },

    props: {
        model: String,
        subject: String,
        cid: String,
        random: Boolean,
        pid: Number
    },

    setup: function (props) {
        const {model, subject, cid, random, pid} = toRefs(props);
        const {questions} = usePracticeQuestions(
            model as Ref<string>,
            subject as Ref<string>,
            cid as Ref<string>,
            random as Ref<boolean>,
            pid as Ref<number>
        );

        const listSetup = useQuestionList(questions);

        const paperId = computed(() => {
            return [cid.value, model.value, subject.value].join('-');
        });

        const answerSheetSetup = useAnswerSheet(paperId, listSetup.current, listSetup.question);

        return {
            questions,
            ...listSetup,
            ...answerSheetSetup
        };
    },

    data: function () {
        let query = this.$route.query;

        return {
            showExplain: false,
            showList: false,
            autoNext: true
        };
    },

    computed: {
        ...mapGetters('lang', ['langDirection'])
    },

    mounted: function () {

        this.$store.dispatch('training/LOAD-USER-ACCESS');
    },

    methods: {

        toggleExplain: function () {
            this.showExplain = !this.showExplain;
        },

        toggleList: function () {
            this.showList = !this.showList;
        },

        confirmAnswer: function (userAnswer) {
            this.addUserAnswer(userAnswer);

            if (userAnswer.correct && this.autoNext) {
                setTimeout(() => {
                    this.next();
                }, 500);
            }
        }
    }
});
</script>

import {defineComponent, toRefs, Ref} from 'vue';
import {useSchoolRank} from '@/compositions/training-school';

export default defineComponent({

    props: {
        city_id: Number
    },

    setup: (props) => {

        const {city_id} = toRefs(props);

        const defaultList = useSchoolRank('recommand', 6, city_id as Ref<number>);

        return {
            ...defaultList
        };
    }
});

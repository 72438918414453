
import {defineComponent, toRefs, Ref} from 'vue';
import {useSchoolRank, useCoachRank} from '@/compositions/training-school';

export default defineComponent({

    props: {
        city_id: Number
    },

    setup: (props) => {

        const {city_id} = toRefs(props);

        const defaultList = useSchoolRank('default', 10, city_id as Ref<number>);
        const ratingList = useSchoolRank('rating', 10, city_id as Ref<number>);
        const coachList = useCoachRank('default', 10, city_id as Ref<number>);

        return {
            defaultList: defaultList.schools,
            ratingList: ratingList.schools,
            coachList: coachList.coachList
        };
    }
});

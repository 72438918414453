<template>
    <div class="container news-page">

        <bread-crumbs />

        <div class="com-tiku-menu clearfix">

            <div class="item-left">
                <p class="title">{{$trans('车型选择')}}</p>
                <a v-for="item in modelList" class="item"
                        :class="[item.iconClass, item.value === model ? 'active' : '']"
                        :key="item.value"
                        @click="changeModel(item.value)">{{$trans(item.label)}}</a>
            </div>

            <div class="item-right">
                <div class="tab-w">

                    <ul class="tab-nav clearfix">
                        <li v-for="item in subjectList" :class="{'active': subject === item.value}"
                                :key="item.value"
                                @click="subject = item.value">
                            <a>{{$trans(item.label)}}</a>
                        </li>
                    </ul>

                    <div class="tab-content clearfix active">
                        <a class="item" v-for="(item, index) in navs" :key="index" @click="navTo(item)">
                            <p class="p1"><img class="icon" :src="item.icon" />{{$trans(item.text)}}</p>
                            <p class="p2">{{$trans(item.label)}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- <chapter-list /> -->

        <div class="frame-l-r clearfix">

        </div>

    </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {mapState} from 'vuex';

import store, {TState} from '@/store';
import {models, subjects} from '@/utils/training';
import {mergeUrlParams} from '@/utils/url';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';
// import ChapterList from '@/components/widgets/chapter-list.vue';

export default defineComponent({
    name: 'exam-index',
    components: {
        BreadCrumbs,
        // ChapterList
    },

    data: function () {
        return {
            model: store.state.training.activeModel,
            subject: store.state.training.activeSubject
        };
    },

    computed: {

        ...mapState<TState>('training', {
            navs: (state) => {
                return state.config.training_pc_nav;
            }
        }),

        modelList: function () {

            let icons = ['cart', 'bus', 'truck', 'mtc', 'cert'];

            return models.map((item) => {
                let iconIndex = icons.indexOf(item.value);

                return {
                    ...item,
                    iconClass: 'icon' + (iconIndex + 1)
                };
            });
        },

        subjectList: function () {

            let model = this.model;

            return subjects.filter((item) => {
                let k2k3 = ['k2', 'k3'].indexOf(item.value) !== -1;

                if (k2k3) {
                    return false;
                }

                let k1k4 = ['k1', 'k4'].indexOf(item.value) !== -1;

                if (model === 'cert') {
                    return !k1k4;
                }
                else {
                    return k1k4;
                }
            });
        }
    },

    methods: {
        changeModel: function (model: string) {
            this.model = model;

            if (model === 'cert') {
                this.subject = 'ptc';
            }
            else if (['k1', 'k4'].indexOf(this.subject) === -1) {
                this.subject = 'k1';
            }
        },

        navTo: function (nav) {

            let query = {};

            if (nav.query) {
                query = nav.query.reduce((ret, key) => {
                    if (['model', 'subject'].indexOf(key) !== -1) {
                        ret[key] = this[key];
                    }
                    return ret;
                }, query);
            }

            if (nav.nav_type === 'route') {
                this.$router.push(mergeUrlParams(nav.url, query));
            }
            else {
                window.location.href = nav.url;
            }
        }
    }
});
</script>

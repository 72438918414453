
import {defineComponent} from 'vue';
import {mapState, mapGetters} from 'vuex';

import store, {TState} from '@/store';
import {models, subjects, modelText, subjectText} from '@/utils/training';
import CitySelect from './city-select.vue';

export default defineComponent({

    name: 'top-bar',

    components: {
        CitySelect
    },

    data: function () {
        return {
            showCitySelect: false
        };
    },

    computed: {
        ...mapState<TState>('training', ['activeModel', 'activeSubject']),

        ...mapGetters('app', ['currentCity', 'isLogin']),
        ...mapState('app', ['userInfo']),

        ...mapState<TState>('lang', ['lang', 'langs']),
        ...mapGetters('lang', ['langName']),

        modelList: function () {
            return models.filter((item) => item.value !== 'cert');
        },

        subjectList: function () {
            return subjects.filter((item) => {
                return ['k1', 'k2', 'k3', 'k4'].indexOf(item.value) === -1;
            });
        },

        activeSubjectText: function () {
            if (this.activeModel === 'cert') {
                return subjectText(this.activeSubject);
            }
            else {
                return modelText(this.activeModel);
            }
        }
    },

    methods: {
        changeModel: function (model) {
            store.commit('training/SET-ACTIVE-MODEL', model);
            store.commit('training/SET-ACTIVE-SUBJECT', 'k1');
        },

        changeCert: function (subject) {
            store.commit('training/SET-ACTIVE-MODEL', 'cert');
            store.commit('training/SET-ACTIVE-SUBJECT', subject);
        },

        showLoginModal: function () {
            store.commit('app/LOGIN-MODAL', true);
        },

        changeLang: function (lang) {
            store.dispatch('lang/LANG', lang);
        },

        logout: function () {
            store.commit('app/LOGOUT');
        }
    }
});

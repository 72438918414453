<template>
    <div class="com-home-school-rank com-part-home">

        <div class="part-home-title">
            <hr class="hr">
            <div class="title-text">驾校教练排行</div>
            <div class="title-text2">口碑人气排行榜，助你轻松找驾校</div>
        </div>

        <div class="content clearfix">

            <div class="item item-left">
                <div class="title-bar">
                    <span class="title-text">权威排行榜</span>
                    <!-- <a class="more">更多</a> -->
                </div>
                <ul class="list-w">

                    <template v-for="(item, index) in defaultList" :key="item.id">
                        <li class="first" v-if="index === 0">
                            <i class="index active">1</i>
                            <a class="img-w" v-if="item.images.length">
                                <img :src="item.images[0]">
                            </a>
                            <div class="row-right">
                                <router-link class="name"
                                        :to="{name: 'school-detail', query: {id: item.id}}">
                                    {{item.name}}
                                </router-link>
                                <p class="info">学员：{{item.students}}人</p>
                                <p class="info">{{item.address}}</p>
                            </div>
                        </li>

                        <li v-else>
                            <i class="index">{{index + 1}}</i>
                            <router-link class="name left"
                                    :to="{name: 'school-detail', query: {id: item.id}}">
                                {{item.name}}
                            </router-link>
                            <span class="orange right">{{item.students}}人</span>
                        </li>
                    </template>

                </ul>
            </div>

            <div class="item item-center">
                <div class="title-bar">
                    <span class="title-text">口碑排行榜</span>
                    <!-- <a class="more">更多</a> -->
                </div>
                <ul class="list-w">
                    <template v-for="(item, index) in ratingList" :key="item.id">
                        <li class="first" v-if="index === 0">
                            <i class="index active">1</i>
                            <a class="img-w" v-if="item.images.length">
                                <img :src="item.images[0]">
                            </a>
                            <div class="row-right">
                                <router-link class="name"
                                        :to="{name: 'school-detail', query: {id: item.id}}">
                                    {{item.name}}
                                </router-link>
                                <div class="star-w-s">
                                    <div class="bfb" :style="{width: item.rating / 5 + '%'}"></div>
                                </div>
                                <p class="info">{{item.address}}</p>
                            </div>
                        </li>

                        <li v-else>
                            <i class="index">{{index + 1}}</i>
                            <router-link class="name left"
                                    :to="{name: 'school-detail', query: {id: item.id}}">
                                {{item.name}}
                            </router-link>
                            <span class="gray left">{{item.rating_count}}点评</span>
                            <div class="star-w-s right">
                                <div class="bfb" :style="{width: item.rating / 5 + '%'}"></div>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>

            <div class="item item-right">
                <div class="title-bar">
                    <span class="title-text">教练排行榜</span>
                    <!-- <a class="more">更多</a> -->
                </div>
                <ul class="list-w coach-list">

                    <li class="coach" v-for="(item, index) in coachList" :key="item.id">
                        <i class="index" :class="{'active': index === 0}">{{index + 1}}</i>
                        <a class="img-w">
                            <img :src="item.avatar" alt="">
                        </a>
                        <div class="row-right">
                            <a class="name">{{item.name}}</a>
                            <p class="info address">
                                <span class="left">学员：{{item.students}}</span>
                                <span class="left mid">{{item.rating_count}}点评</span>
                                <span class="orange right">{{item.rating}}分</span>
                            </p>
                        </div>
                    </li>

                </ul>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, toRefs, Ref} from 'vue';
import {useSchoolRank, useCoachRank} from '@/compositions/training-school';

export default defineComponent({

    props: {
        city_id: Number
    },

    setup: (props) => {

        const {city_id} = toRefs(props);

        const defaultList = useSchoolRank('default', 10, city_id as Ref<number>);
        const ratingList = useSchoolRank('rating', 10, city_id as Ref<number>);
        const coachList = useCoachRank('default', 10, city_id as Ref<number>);

        return {
            defaultList: defaultList.schools,
            ratingList: ratingList.schools,
            coachList: coachList.coachList
        };
    }
});
</script>
<template>
    <div class="layout-navigation clearfix">

        <div class="navigation-top clearfix">
            <div class="logo-w">
                <a class="logo" href="/" style="background: none;">
                    <img :src="config.login_logo" style="height: 100%" />
                </a>
            </div>

            <!-- <div class="search-w">
                <form action="https://www.so.com/s" target="_blank">
                    <input type="text" placeholder="请输入关键字" autocomplete="off" name="q" id="so360_keyword" />
                    <input type="submit" class="button" value="搜 索" />
                    <input type="hidden" name="ie" value="utf8" />
                    <input type="hidden" name="src" value="" />
                    <input type="hidden" name="site" value="" />
                    <input type="hidden" name="rg" value="1" />
                </form>
            </div> -->

        </div>

        <div class="navigation-content">
            <ul class="nav-w clearfix">
                <li v-for="item in navs" :key="item.path" :class="{'menu-jx': item.children}">
                    <router-link :to="item.path">{{item.title}}</router-link>

                    <div class="drop-w" v-if="item.children">
                        <router-link v-for="(sub, index) in item.children" :key="index"
                                active-class=""
                                :to="sub.path">
                            {{sub.title}}
                        </router-link>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapState} from 'vuex';

export default defineComponent({
    name: 'main-nav',

    data: function () {
        return {
            navs: [
                {
                    path: '/',
                    title: '首页'
                },
                {
                    path: '/exam',
                    title: '模拟考试'
                },
                {
                    path: '/videos',
                    title: '视频学车',
                    children: [
                        {
                            path: '/videos/index?subject=k2',
                            title: '科目二'
                        },
                        {
                            path: '/videos/index?subject=k3',
                            title: '科目三'
                        }
                    ]
                },
                {
                    path: '/school',
                    title: '找驾校'
                },
                {
                    path: '/bbs',
                    title: '驾考圈'
                },
                {
                    path: '/signs',
                    title: '交通标志'
                }
            ]
        };
    },

    computed: {
        ...mapState('training', ['config'])
    }
});
</script>
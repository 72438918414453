import { createStore } from 'vuex';

import modules, {TState} from './modules';

export {TState} from './modules';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore<TState>({
    mutations: {
    },
    actions: {
    },
    modules: modules,
    strict: debug
});

(window as any).store = store;

export default store;

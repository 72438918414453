<template>
    <div class="com-shiti-xiangjie com-part">

        <template v-if="question.explain_jq">
            <h3 class="part-title">{{$trans('本题技巧')}}</h3>
            <div class="concise-w">
                <p class="concise-t"></p>
                <p v-if="skillAccess">
                    <img v-if="question.explain_gif" :src="question.explain_gif" alt="" style="max-width: 100%; margin-bottom: 20px;" />
                    <span v-html="explainJq"></span>
                    <a v-if="question.explain_mp3" class="blue2" style="margin-left: 20px;" @click="voiceExplain">语音讲解</a>
                </p>
                <p v-else>{{question.explain_jq.slice(0, 5)}}...</p>
                <p v-if="!skillAccess" class="view-concise" @click="showExplainJq">{{$trans('查看完整技巧')}}</p>
            </div>
        </template>

        <template v-if="question.explain_js">
            <h3 class="part-title">
                {{$trans('试题详解')}}
                <!-- <a class="more red" target="_blank">我要报错</a> -->
            </h3>
            <div class="xiangjie">
                <div class="nandu clearfix">
                    <span class="lable left">{{$trans('难度等级')}}：</span>
                    <div class="star-w-s left">
                        <div class="bfb" style="width: 80%"></div>
                    </div>
                </div>
                <div class="content">
                    <p>{{question.explain_js}}</p>
                </div>
            </div>
        </template>

        <!-- <div class="woyaofenxi"><a>我要分析</a></div>
        <div class="com-news-dianping-list com-part">
            <h3 class="part-title">我来分析</h3>
            <ul>
                <li class="clearfix">
                    <div class="item-left">
                        <div class="img-w"><img src=""></div>
                    </div>
                    <div class="item-right">
                        <div class="content-top clearfix">
                            <span class="author-name left">小小</span><span class="city left">[武汉市]</span><span class="left jing">精华</span>
                        </div>
                        <p class="content">违反道路交通安全法简称违法。官方已无违规、违章的说法。
                            题目太多不想做？VIP500题来帮你！❤关注小学姐，驾考一把过</p>
                        <div class="content-bottom clearfix">
                            <span class="date left">2020-11-05 14:53</span>
                            <a class="huifu right" title="回复"></a>
                            <a class="zan right" title="点赞">454</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div> -->
    </div>

    <!-- <div class="com-dialog-reply-error">
        <div class="com-dialog-template hide">
            <div class="mengban"></div>
            <div class="table">
                <div class="table-cell">
                    <div class="dialog-box"><a class="close">×</a>
                        <div class="dialog-content">
                            <form>
                                <p class="dialog-title">我要报错</p>
                                <div class="row row1"><textarea name="content" placeholder="请描述一下试题详解错误原因" maxlength="300"></textarea></div>
                                <div class="row row3"><button type="submit">提交</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import {replaceMark} from '@/utils/training';
import {audioPlay} from '@/utils/media';

export default defineComponent({
    name: 'explain-panel',

    props: {
        question: Object,
        subject: String
    },

    data: function () {
        return {};
    },

    computed: {
        ...mapGetters('training', ['trainingAccess']),

        skillAccess: function () {

            return this.trainingAccess(this.subject);
        },

        explainJq: function () {

            return replaceMark(this.question.explain_jq);
        }
    },

    methods: {

        voiceExplain: function () {
            if (this.question.explain_mp3) {
                console.log(this.question.explain_mp3);

                audioPlay(this.question.explain_mp3, true);
            }
        },

        showExplainJq: function () {
            this.$store.commit('app/H5-QRCODE-MODAL', true);
        }
    }
});
</script>

<style scoped>
.keyword,
.mark {
    color: #f0ad4e;
}
</style>
export function markKeywords (text: string, keywords: string[] | string): string {

    if (!text || !keywords) {
        return '';
    }

    if (typeof keywords === 'string') {
        keywords = keywords.split('-');
    }

    let reg = new RegExp(keywords.join('|'), 'g');
    return text.replace(reg, (match) => {
        return '<span style="color: red; font-weight: bold;">' + match + '</span>';
    });

    return text;
}

export function replaceMark (text: string): string {

    if (!text) {
        return '';
    }

    // ydt 题库的关键词标记方式
    text = text.replace(/【/g, '<span class="mark">');
    text = text.replace(/】/g, '</span>');
    text = text.replace(/｛/g, '<span class="keyword">');
    text = text.replace(/｝/g, '</span>');
    //
    text = text.replace(/{/g, '<span class="keyword">');
    text = text.replace(/}/g, '</span>');

    return text;
}

export function typeText (type) {
    return {
        1: '判断题',
        2: '单选题',
        3: '多选题'
    }[type];
}

export const subjects = [
    {value: 'k1', label: '科目一'},
    {value: 'k2', label: '科目二'},
    {value: 'k3', label: '科目三'},
    {value: 'k4', label: '科目四'},
    {value: 'ptc', label: '客运资格证'},
    {value: 'ftc', label: '货运资格证'},
    {value: 'dtc', label: '危险品资格证'}
];

export const questionSubjects = subjects.filter((item) => ['k2', 'k3'].indexOf(item.value) === -1);

const subjectMap = map(subjects);

export function subjectText (subject) {

    return subjectMap[subject];
}

export const models = [
    {value: 'cart', label: '小车'},
    {value: 'bus', label: '客车'},
    {value: 'truck', label: '货车'},
    {value: 'mtc', label: '摩托车'},
    {value: 'cert', label: '资格证'}
];

const modelMap = map(models);

export function modelText (subject) {
    return modelMap[subject];
}

function map (arr, keyName = 'value', valueName = 'label') {

    return arr.reduce((ret, item) => {
        ret[item[keyName]] = item[valueName];
        return ret;
    }, {});
}

export function abcIndex (index: number, answer = false) {

    if (answer) {
        let str = '';
        for (let i = 0; i < 6; i++) {
            let pos = 1 << i;
            if (index & pos) {
                str += 'ABCDEF'[i];
            }
        }

        return str;
    }
    else {
        return 'ABCDEF'[index];
    }
}
import store from "../store";
import Cache from '../utils/cache';

import {LOGIN_STATUS, USER_INFO, EXPIRES_TIME, STATE_R_KEY} from '../config/cache';

export function toLogin() {

    store.commit("LOGOUT");

    store.commit('app/LOGIN-MODAL', true);

    // Cache.set('login_back_url', location.pathname + location.search);

    // location.href = '/page/users/login/index';
}


export function checkLogin() {
    let token = Cache.get(LOGIN_STATUS);
    let expiresTime = Cache.get(EXPIRES_TIME);
    let newTime = Math.round(Date.now() / 1000);

    if (expiresTime < newTime || !token) {
        Cache.clear(LOGIN_STATUS);
        Cache.clear(EXPIRES_TIME);
        Cache.clear(USER_INFO);
        Cache.clear(STATE_R_KEY);
        return false;
    } else {
        store.commit('app/UPDATE_LOGIN', token);
        let userInfo = Cache.get(USER_INFO, null);
        if (userInfo) {
            store.commit('app/UPDATE_USERINFO', userInfo);
        }
        return true;
    }

}

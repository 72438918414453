<template>
    <div class="container news-page">

        <bread-crumbs />

        <div class="frame-l-r">
            <div class="left">

                <practice-paper :model="model" :subject="subject" :cid="cid" :random="random" :pid="pid" />

                <!-- <div class="com-jiaxiao-list-random com-part">
                    <h3 class="part-title">还没报驾校？看看大家报的驾校</h3>
                    <ul class="list-w">
                        <li><a class="col col1" target="_blank" href="/school/31050/"><span class="dian">•</span>北京市城市学</a><span class="col col2">C1/C2</span>
                            <div class="col col2 star-w-s">
                                <div class="bfb" style="width: 60%;"></div>
                            </div><span class="col col4">面议</span>
                        </li>
                    </ul>
                </div> -->

            </div>

            <!-- <div class="right">
                <div class="com-side-car-sales-rank com-part">
                    <h3 class="part-title">汽车销量排行<a class="more" rel="nofollow">更多&gt;</a></h3>
                    <div class="iframe-w">
                        <iframe src="" scrolling="no" frameborder="0" height="500px" width="100%"></iframe>
                    </div>
                </div>
            </div> -->

        </div>

        <!-- <div class="hide">
            <div class="qj-mask">
                <div class="qj-wrap">
                    <div ref="close" class="close"></div>
                    <div class="qj-img">
                        <p class="price-w"><label>¥</label><span>45.00</span></p>
                    </div>
                    <div class="qj-con">
                        <div class="div2">
                            <p class="p3"><i>-</i><label>限时附赠4大权益</label><i>-</i></p>
                            <ul>
                                <li><label class="i1"></label><span>精选题库</span></li>
                                <li><label class="i2"></label><span>三天特训</span></li>
                                <li><label class="i3"></label><span>速成500题</span></li>
                                <li><label class="i4"></label><span>安心保障</span></li>
                            </ul>
                        </div>
                        <div class="unlock" ref="buy">立即购买</div>
                    </div>
                </div>
                <div class="pay-mask hide">
                    <div class="pay-wrap">
                        <div ref="close" class="close"></div>
                        <div class="pay-hd">
                            <div class="avatar"><img src=""></div>
                            <div class="nickname"></div>
                        </div>
                        <p class="title">VIP会员</p>
                        <ul class="ul2">
                            <li><label class="i1"></label><span>做题免广告</span></li>
                            <li><label class="i2"></label><span>预测通过率</span></li>
                            <li><label class="i3"></label><span>驾考讲堂</span></li>
                            <li><label class="i4"></label><span>易错题集</span></li>
                            <li><label class="i5"></label><span>尊贵身份标识</span></li>
                            <li><label class="i6"></label><span>智能练题</span></li>
                        </ul>
                        <div class="pay-con">
                            <div class="pay-op">
                                <p class="active" ref="wxpay"><label class="label1">微信支付</label></p>
                                <p class="" ref="alipay"><label class="label2">支付宝支付</label></p>
                            </div>
                            <div class="pay-result">
                                <div class="pay-qr" ref="qr"></div>
                                <div class="pay-desc">
                                    <p class="p1">微信扫码</p>
                                    <p class="p2">支付<span>--</span>元</p>
                                </div>
                            </div>
                        </div>
                        <div class="protocol">
                            <label>&nbsp;</label>
                            <span>已查看并同意<a>《会员协议》</a>
                                <a>《保障套餐协议》</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';
import PracticePaper from '@/components/exam/practice-paper.vue';

export default defineComponent({
    name: 'practice-view',

    components: {
        BreadCrumbs,
        PracticePaper
    },

    props: {
        subject: String,
        model: String,
        cid: String,
        random: Boolean,
        pid: Number
    }
});
</script>

<style>

</style>
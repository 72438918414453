
import {Getter} from 'vuex';

import {getUserInfo} from "@/api/user";
import {getLogo} from '@/api/public';
import {LOGIN_STATUS, UID} from '@/config/cache';
import Cache from '@/utils/cache';
import {USER_INFO} from '@/config/cache';
import {getCityData, getCityInfo} from '@/api/training';
import pinyin from '@/utils/pinyin';
import {jsonp} from '@/utils/request';

const state = {
    loginModal: false,
    token: Cache.get(LOGIN_STATUS) || false,
    backgroundColor: "#fff",
    userInfo: Cache.get(USER_INFO, null),
    uid: Cache.get(UID) || 0,
    homeActive: false,
    phoneStatus:true,

    city: Cache.get('city', []),
    city_id: Cache.get('city_id', 0),
    cityData: Cache.get('cityData', []),
    location: null,

    confirmModal: null,
    h5QrcodeModal: false
};

interface TCityObject {
    v: number;
    n: string;
    c?: TCityObject[];
}

const getCityIds = (city_id: number, cityData: TCityObject[]) => {
    let ids: number[] = [];

    for (let i = 0; i < cityData.length; i++) {
        let province = cityData[i];
        if (ids.length) {
            ids.pop();
        }

        ids.push(province.v);

        if (city_id === province.v) {
            return ids;
        }

        if (province.c.length) {
            for (let j = 0; j < province.c.length; j++) {
                let city = province.c[j];
                if (ids.length > 1) {
                    ids.pop();
                }

                ids.push(city.v);

                if (city_id === city.v) {
                    return ids;
                }

                if (city.c.length) {
                    for (let k = 0; k < city.c.length; k++) {
                        let area = city.c[k];
                        if (ids.length > 2) {
                            ids.pop();
                        }
                        ids.push(area.v);

                        if (city_id === area.v) {
                            return ids;
                        }
                    }
                    ids.pop();
                }
            }
            ids.pop();
        }
    }
    ids.pop();

    return ids;
};

export type TState = typeof state;

const getters: {[key: string]: Getter<TState, any>} = {

    isLogin: state => !!state.token,

    cityMap: (state) => {
        const map = {};

        state.cityData.forEach((p) => {
            map[p.v] = p;

            if (p.c) {
                p.c.forEach((c) => {
                    map[c.v] = c;

                    if (c.c) {
                        c.c.forEach((a) => {
                            map[a.v] = a;
                        });
                    }
                });
            }
        });

        return map;
    },

    currentCity: (state, getters) => {
        let city_id = state.city_id;

        if (city_id) {
            return getters.cityMap[city_id];
        }

        return null;
    },

    getCityIds: (state) => (city_id) => {

        return getCityIds(city_id, state.cityData);
    }
};

const mutations = {

    SETPHONESTATUS(state,val){
        state.phoneStatus = val;
    },

    LOGIN(state, opt) {
        state.token = opt.token;
        Cache.set(LOGIN_STATUS, opt.token, opt.time);
    },

    SETUID(state,val){
        state.uid = val;
        Cache.set(UID, val);
    },

    UPDATE_LOGIN(state, token) {
        state.token = token;
    },

    LOGOUT(state) {
        state.token = undefined;
        state.uid = undefined
        Cache.clear(LOGIN_STATUS);
        Cache.clear(UID);
    },

    BACKGROUND_COLOR(state, color) {
        state.color = color;
        document.body.style.backgroundColor = color;
    },

    UPDATE_USERINFO(state, userInfo) {
        state.userInfo = userInfo;
        Cache.set(USER_INFO, userInfo);
    },

    OPEN_HOME(state) {
        state.homeActive = true;
    },

    CLOSE_HOME(state) {
        state.homeActive = false;
    },

    'CITY-ID': (state, city_id) => {
        state.city_id = city_id;
        Cache.set('city_id', city_id);
    },

    'SELECT-CITY': (state, city) => {
        state.city = city;
        Cache.set('city', city);
    },

    'CITY-DATA': (state, data) => {

        data.forEach((province) => {

            let p = pinyin(province.n);

            province.i = p[0][0];

            // 重是多音字，第一个返回的不是chong
            if (province.n.indexOf('重庆') === 0) {
                province.i = 'C';
            }
        });

        state.cityData = data;
        Cache.set('cityData', data, 3600 * 24 * 7);
    },

    'LOCATION': (state, location) => {
        state.location = location;
    },

    'LOGIN-MODAL': (state: TState, status: boolean) => {
        state.loginModal = status;
    },

    'CONFIRM-MODAL': (state: TState, modal: null | {title: string; cancelText?: string; confirmText?: string;}) => {
        state.confirmModal = modal;
    },

    'H5-QRCODE-MODAL': (state: TState, status: boolean) => {
        state.h5QrcodeModal = status;
    }
};

const actions = {
    'USERINFO': ({state, commit }, force) => {
        if (state.userInfo !== null && !force)
            return Promise.resolve(state.userInfo);
        else
            return getUserInfo().then(res => {
                commit("UPDATE_USERINFO", res.data);
                commit('SETUID', res.data.uid);
                Cache.set(USER_INFO, res.data);
                return res.data;
            });
    },

    'LOAD-CITY': (context, payload) => {

        if (!Cache.get('cityData', []).length) {
            getCityData().then((res) => {
                context.commit('CITY-DATA', res.data);
            });
        }
    },

    'SHOW-CONFIRM': (context, modal) => {

        context.commit('CONFIRM-MODAL', modal);

        return () => {
            context.commit('CONFIRM-MODAL', null);
        };
    },

    'GET-LOCATION': (context, force) => {

        if (!context.state.city_id || force) {
            let mapKey = context.rootState.training.config.tengxun_map_key;

            jsonp(`https://apis.map.qq.com/ws/location/v1/ip?output=jsonp&key=${mapKey}`, (res) => {

                if (res.result) {
                    let coord = res.result.location;

                    getCityInfo({
                        lat: coord.lat,
                        lng: coord.lng
                    }).then((ret) => {

                        if (ret.data.cityinfo) {
                            context.commit('CITY-ID', ret.data.cityinfo.city_id);
                            context.commit('SELECT-CITY', getCityIds(ret.data.cityinfo.city_id, context.state.cityData));
                        }
                    });
                }
            });
        }
    }

    // 'GET-LOCATION': (context, force = false) => {

    //     if (!context.state.city_id || force) {
    //         util.$L.getLocation().then((res) => {
    //             context.commit('LOCATION', res);

    //             getCityInfo({
    //                 lat: res.latitude,
    //                 lng: res.longitude
    //             }).then((ret) => {
    //                 context.commit('CITY-ID', ret.data.cityinfo.city_id);
    //             });
    //         });
    //     }
    // }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

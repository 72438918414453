<template>
    <div class="container home">
        <div class="layout-user clear-cache">
            <top-bar />
        </div>

        <div class="layout-header">
            <main-nav />
        </div>

        <div class="layout-article">
            <router-view />
        </div>

        <div class="layout-footer">
            <main-footer />
        </div>

        <confirm-modal v-if="confirmModal"
                :title="confirmModal.title"
                :cancelText="confirmModal.cancelText"
                :confirmText="confirmModal.confirmText"
                :confirm="confirmModal.confirm"
                :mask="confirmModal.mask"
                :autoclose="confirmModal.autoclose"
                @confirm="closeConfirmModal" />

        <teleport to="body">
            <login-modal v-if="loginModal" />

            <h-5-qrcode-modal v-if="h5QrcodeModal" />
        </teleport>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {mapState, mapGetters} from 'vuex';

import store from '@/store';

import TopBar from './components/header/top-bar.vue';
import MainNav from './components/header/main-nav.vue';
import MainFooter from './components/footer/main-footer.vue';
import LoginModal from '@/components/modals/login.vue';
import H5QrcodeModal from '@/components/modals/h5-qrcode-modal.vue';
import ConfirmModal from '@/components/modals/confirm.vue';

export default defineComponent({
    components: {
        TopBar,
        MainNav,
        MainFooter,
        LoginModal,
        H5QrcodeModal,
        ConfirmModal
    },

    computed: {
        ...mapState('app', ['loginModal', 'confirmModal', 'h5QrcodeModal']),

        ...mapGetters('app', ['isLogin'])
    },

    mounted: function () {

        store.dispatch('training/FETCH-CONFIG').then((res) => {
            store.commit('lang/UPDATE-LANGS', res.data.langs);
            store.dispatch('lang/LOAD-LANG-DEF', {});

            store.dispatch('app/GET-LOCATION');
        });

        store.dispatch('app/LOAD-CITY');

        if (this.isLogin) {
            store.dispatch('app/USERINFO');
        }
    },

    methods: {
        closeConfirmModal: function () {
            store.commit('app/CONFIRM-MODAL', null);
        }
    }
});
</script>

<style>

</style>

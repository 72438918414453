const production = process.env.NODE_ENV === "production";

export const HTTP_REQUEST_URL = production ?
        window.location.protocol + "//" + window.location.host :
        'http://localhost:7802';

export const VUE_APP_WS_URL = `ws://${window.location.host}:20083`;

export const HEADER: HeadersInit = {
    'content-type': 'application/json',
    'Form-type': 'h5',
};

// 回话密钥名称 请勿修改此配置
export const TOKENNAME = 'Authori-zation';

// 缓存时间 0 永久
export const EXPIRE = 0;

//分页最多显示条数
export const LIMIT = 10;
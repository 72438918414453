<template>
    <div class="exam" :style="{direction: langDirection}" :direction="langDirection">
        <div class="row clearfix">

            <div class="col col1">
                <fieldset class="kaochang">
                    <legend>{{$trans('理论考试')}}</legend><span>{{$trans('第01考台')}}</span>
                </fieldset>
                <fieldset class="kaosheng">
                    <legend>{{$trans('考生信息')}}</legend>
                    <div class="author-img"></div>
                    <div class="kaosheng-item kemu red">{{$trans(subjectText)}}</div>
                    <div class="kaosheng-item name">{{$trans('考生姓名')}}：</div>
                    <div class="kaosheng-item">{{$trans('考试题数')}}：{{questions.length}}</div>
                    <div class="kaosheng-item">{{$trans('考试时间')}}：{{$trans('%d分钟', totalTime / 60)}}</div>
                    <div class="kaosheng-item">{{$trans('满分100分，90分及格')}}</div>
                </fieldset>
                <fieldset class="daojishi">
                    <legend>{{$trans('剩余时间')}}</legend><span class="djs">{{countDownText}}</span>
                </fieldset>
            </div>

            <div class="col col2" v-if="question">
                <fieldset class="kaoti">
                    <legend>{{$trans('考试题目')}}</legend>
                    <div class="com-mnks-question-detail">
                        <div class="detail-content" id="ComQuestionInfo_qundefined">
                            <div class="timu">
                                <p v-html="titleHTML"></p>
                            </div>
                            <div class="answer-w clearfix">
                                <div class="options-w">
                                    <p v-for="(item, index) in options" :key="index"
                                            :class="{'success': selected & (1 << index)}"
                                            @click="select(index)" v-html="[abcIndex(index), '、', replaceMark(item.option)].join('')">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix">

                        <div class="user-answer" v-if="currentAnswer">

                            <span class="blue2" v-if="currentAnswer.correct">{{$trans('回答正确')}} &nbsp;&nbsp;</span>
                            <template v-else>
                                <span class="red">{{$trans('回答错误')}}&nbsp;&nbsp;</span>
                                {{$trans('您的答案')}}：<span class="blue2">{{abcIndex(currentAnswer.answer, true)}}</span>&nbsp;&nbsp;
                            </template>

                            {{$trans('正确答案')}}：<span class="blue2">{{answerABC}}</span>
                            <a class="blue2 why" v-if="question.explain_js" @click="showExplain">{{$trans('为什么')}}</a>
                        </div>

                        <div class="select-w right" v-else>
                            <label>{{$trans('请选择')}}:</label>
                            <button class="select-lable" v-for="(item, index) in options"
                                    :key="index"
                                    :class="{'active': selected & (1 << index)}"
                                    @click="select(index)">
                                {{abcIndex(index)}}
                            </button>
                            <button class="btn-answer-ok" v-if="isMultiAnswer" @click="confirmMultiAnswer">{{$trans('确定')}}</button>
                        </div>
                    </div>
                </fieldset>

                <div class="kaoti-bar clearfix">
                    <fieldset class="tishi">
                        <legend>{{$trans('试题提示信息')}}</legend>
                        <span v-if="question.type === 1">{{$trans('判断题，请判断对错！')}}</span>
                        <span v-else-if="question.type === 2">{{$trans('单选题，请选择你认为正确的答案！')}}</span>
                        <span v-else-if="question.type === 3">{{$trans('多选题，请至少选择两个正确答案！')}}</span>
                    </fieldset>
                    <div class="btn-bar" style="direction: ltr;">
                        <button class="pre" @click="prev">{{$trans('上一题')}}</button>
                        <button class="next" @click="next">{{$trans('下一题')}}</button>
                        <button class="submit" @click="handIn">{{$trans('交卷')}}</button>
                    </div>
                </div>
            </div>

            <div class="col col3">
                <div class="datika">
                    <ul class="datika-list-w clearfix">
                        <li v-for="(item, index) in questions" :key="item.id">
                            <a :class="[index === current ? 'curr' : '', userAnswers[item.id] ? (userAnswers[item.id].correct ? 'right' : 'error') : '']"
                                    @click="current = index">{{index + 1}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <fieldset class="tishi-img">
            <legend>{{$trans('图片信息')}}</legend>
            <div class="media-w" v-if="question && question.image">
                <img :src="question.image" />
            </div>
        </fieldset>
    </div>
</template>

<script lang="ts">
import {defineComponent, toRefs, ref, Ref, computed} from 'vue';
import {mapGetters} from 'vuex';

import {abcIndex, subjectText} from '@/utils/training';
import {timeFormat} from '@/utils/datetime';
import {logExamResult} from '@/api/training';
import {replaceMark} from '@/utils/training';

import {
    useAnswerSheet, useQuestionList, useExamQuestions,
    useQuestionDetail, usePaperCountDown
} from '@/compositions/question-paper';

export default defineComponent({
    name: 'mock-paper',

    components: {

    },

    props: {
        model: String,
        subject: String
    },

    setup: function (props) {
        const {model, subject} = toRefs(props);
        const {questions} = useExamQuestions(model as Ref<string>, subject as Ref<string>);

        const listSetup = useQuestionList(questions);

        const paperId = ref('');

        const answerSheetSetup = useAnswerSheet(paperId, listSetup.current, listSetup.question);

        const quetionDetailSetup = useQuestionDetail(listSetup.question, answerSheetSetup.currentAnswer);

        const countDownSetup = usePaperCountDown(props.subject as string);

        return {
            questions,
            ...listSetup,
            ...answerSheetSetup,
            ...quetionDetailSetup,
            ...countDownSetup
        };
    },

    data: function () {

        return {
            failed: false
        };
    },

    mounted: function () {

        this.$store.dispatch('app/SHOW-CONFIRM', {
            title: this.$trans('模拟考试考试下不能修改答案，每做一题，系统自动计算错误题数，及格标准为90分。'),
            cancelText: '',
            confirm: () => {
                this.startCountDown();
            }
        });
    },

    computed: {
        ...mapGetters('lang', ['langDirection']),
        answerABC: function () {
            if (this.question) {
                if (this.question.type === 1) {
                    let answer = this.question.answer === 1 ? 1 : 2;
                    return this.abcIndex(answer, true);
                }
                else {
                    return this.abcIndex(this.question.answer, true);
                }
            }

            return '';
        },

        countDownText: function () {

            return timeFormat(this.totalTime - this.timecost);
        },

        subjectText: function () {

            return subjectText(this.subject);
        },

        titleHTML: function () {

            return [this.current + 1, '、', replaceMark(this.question.title)].join('');
        }
    },

    watch: {
        timecost: function () {
            if (this.timecost >= this.totalTime) {
                this.timeup();
            }
        },

        failedCount: function () {
            let total = this.questions.length;
            let scorePerQuestion = 100 / total;
            let lostScore = this.failedCount * scorePerQuestion;

            if (lostScore > 10) {
                this.fail();
            }
        }
    },

    methods: {

        replaceMark: replaceMark,

        abcIndex: function (index, answer = false) {

            return abcIndex(index, answer);
        },

        toggleExplain: function () {
            this.showExplain = !this.showExplain;
        },

        toggleList: function () {
            this.showList = !this.showList;
        },

        confirmAnswer: function () {

            let answer = this.selected;
            let correct;

            if (this.question.type === 1) {
                answer = answer === 1 ? 1 : 0; // 判断题第一个选项正确，第二个选项错误
            }

            correct = this.question.answer === answer;

            if (correct) {
                if (this.failed) {
                    this.$store.commit('training/REMOVE-FAILED-QUESTION', this.question.id);
                }
            }
            else {
                if (!this.free) {
                    this.$store.commit('training/ADD-FAILED-QUESTION', {
                        id: this.question.id,
                        subject: this.subject,
                        model: this.model,
                        lang: this.$store.state.lang.lang
                    });
                }
            }

            let userAnswer = {
                id: this.question.id,
                answer: this.selected,
                correct: correct
            };

            this.addUserAnswer(userAnswer);

            setTimeout(() => {
                this.next();
            }, 200);
        },

        confirmMultiAnswer: function () {
            if (this.selected) {
                this.confirmAnswer();
            }
        },

        select: function (index) {
            if (this.selectOption(index)) {
                if (!this.isMultiAnswer) {
                    this.confirmAnswer();
                }
            }
        },

        handIn: function () {

            this.$store.dispatch('app/SHOW-CONFIRM', {
                title: this.$trans('确认要交卷吗？'),
                confirm: (ok) => {
                    if (ok) {
                        this.showResult();
                    }
                }
            });
        },

        showResult: function () {

            let total = this.questions.length;
            let scorePerQuestion = 100 / total;

            if (scorePerQuestion !== 1 && scorePerQuestion !== 2) {
                if (this.model === 'mtc') {
                    scorePerQuestion = 2;
                }
                else if (this.subject === 'k4') {
                    scorePerQuestion = 2;
                }
                else {
                    scorePerQuestion = 1;
                }
            }

            let result = {
                subject: this.subject,
                model: this.model,
                timecost: this.timecost,
                timetotal: this.totalTime,
                score: this.correctCount * scorePerQuestion,
                total_score: total * scorePerQuestion,
                correct: this.correctCount,
                total: total,
                error: this.failedCount,
                lang: this.$store.state.lang.lang
            };

            if (result.score) {
                logExamResult(result);
            }

            this.stopCountDown();
            this.$router.replace('/exam/award?result=' + encodeURIComponent(JSON.stringify(result)));
        },

        showExplain: function () {
            if (this.question.explain_js) {
                this.$store.dispatch('app/SHOW-CONFIRM', {
                    title: this.question.explain_js,
                    cancelText: ''
                });
            }
        },

        timeup: function () {
            this.$store.dispatch('app/SHOW-CONFIRM', {
                title: this.$trans('考试时间结束，自动交卷'),
                cancelText: '',
                confirm: () => {
                    this.showResult();
                }
            });
        },

        fail: function () {

            if (!this.failed) {
                this.failed = true;

                this.$store.dispatch('app/SHOW-CONFIRM', {
                    title: this.$trans('答错 %d 题，考试失败, 是否交卷？', this.failedCount),
                    confirmText: this.$trans('交卷'),
                    cancelText: this.$trans('继续答题'),
                    confirm: (ok) => {
                        if (ok) {
                            this.showResult();
                        }
                    }
                });
            }
        }
    }
});
</script>

<style scoped>
.com-mnks-question-detail {
    height: 260px;
    overflow: auto
}
</style>

import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import {replaceMark} from '@/utils/training';
import {audioPlay} from '@/utils/media';

export default defineComponent({
    name: 'explain-panel',

    props: {
        question: Object,
        subject: String
    },

    data: function () {
        return {};
    },

    computed: {
        ...mapGetters('training', ['trainingAccess']),

        skillAccess: function () {

            return this.trainingAccess(this.subject);
        },

        explainJq: function () {

            return replaceMark(this.question.explain_jq);
        }
    },

    methods: {

        voiceExplain: function () {
            if (this.question.explain_mp3) {
                console.log(this.question.explain_mp3);

                audioPlay(this.question.explain_mp3, true);
            }
        },

        showExplainJq: function () {
            this.$store.commit('app/H5-QRCODE-MODAL', true);
        }
    }
});

import {ActionContext} from 'vuex';

import Cache from '@/utils/cache';
import {sprintf} from '@/utils/string';
import {getLangList} from '@/api/api';

const state = {
    lang: Cache.get('lang', 'zh-cn'),
    langs: [{label: '汉语', lang: 'zh-cn'}],
    defs: Cache.get('lang-defs', {})
};

export type TState = typeof state;

const getters = {

    langName: (state) => {

        let lang = state.langs.find((item) => item.lang === state.lang);

        return lang ? lang.label : state.lang;
    },

    trans: (state) => (text, ...args) => {

        if (!state.lang || state.lang === 'zh-cn') {
            return args.length ? sprintf(text, ...args) : text;
        }

        let def = state.defs[state.lang];
        let defText = def ? def[text] : '';

        if (defText) {
            return args.length ? sprintf(defText, ...args) : defText;
        }

        // console.error('多语言未定义:' + text);

        return args.length ? sprintf(text, ...args) : text;
    },

    langDirection: (state) => {
        let langs = state.langs;
        let lang = state.lang;
        let langConfig = langs.find((item) => item.lang === lang);

        let direction = '';
        if (langConfig) {
            direction = langConfig.direction;
        }

        return direction;
    }
};

const mutations = {
    'LANG': (state, lang) => {
        state.lang = lang;
        Cache.set('lang', lang);
    },

    'UPDATE-LANGS': (state, langs) => {
        state.langs = langs;
    },

    'UPDATE-DEFS': (state, defs) => {
        let newDefs = {...state.defs};

        Object.keys(defs).forEach((key) => {
            newDefs[key] = {
                ...newDefs[key],
                ...defs[key]
            };
        });

        state.defs = newDefs;

        Cache.set('lang-defs', newDefs);
    }
};

const actions = {
    'LANG': (context, lang) => {
        context.dispatch('LOAD-LANG-DEF', {lang: lang}).then(() => {
            context.commit('LANG', lang);
        });
    },

    // 加载多语言包
    'LOAD-LANG-DEF': (context: ActionContext<TState, any>, payload) => {

        let lang = payload.lang || context.state.lang;

        if (lang && (!context.state.defs[lang] || payload.force)) {
            return getLangList({result_lang: lang}).then((res) => {

                let defs = res.data.reduce((ret, item) => {

                    ret[item.source] = item.result;

                    return ret;
                }, {});

                context.commit('UPDATE-DEFS', {
                    [lang]: defs
                });

                return res;
            });
        }

        return Promise.resolve();
    }
};

export default {

    namespaced: true,

    state,

    getters,

    mutations,

    actions
};
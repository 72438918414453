<template>
    <div class="content clearfix">

        <!-- <div class="common-link">
            <div class="common-tab clearfix">
                <div class="tab">
                    <ul>
                        <li class="active " style="width:25.000%"><span>友情链接</span></li>
                        <li class="" style="width:25.000%"><span>热门驾校</span></li>
                        <li class="" style="width:25.000%"><span>周边驾校</span></li>
                        <li class="" style="width:25.000%"><span>热门推荐</span></li>
                    </ul>
                </div>
                <div class="content">
                    <div class="item active">
                        <div class="clearfix" key="content_0" skip="true">
                            <ul class="home-link-list friendLink">
                                <li><a>买车网</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="footer3">
            <div class="left">
                <a class="index-logo">
                    <img :src="config.site_logo" class="no-bg" />
                </a>
            </div>
            <div class="right" style="height: auto;">

                <ul class="cl" v-if="config.pc_footer_links">
                    <template v-for="(item, index) in config.pc_footer_links" :key="index">
                        <li class="bd-line" v-if="index !== 0"></li>
                        <li>
                            <a :href="item.url" target="_blank" class="a-link" :class="{'joinus': index === 0}">
                                {{item.text}}
                            </a>
                        </li>
                    </template>
                </ul>

                <p>地址：{{config.site_address}}&nbsp;&nbsp;电话：{{config.site_phone}}</p>
                <p><!-- <a class="ghsImg"></a>&nbsp;&nbsp; --><a>{{config.site_recordation}}</a></p>
                <p>Copyright © {{config.site_copyright}}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapState} from 'vuex';

export default defineComponent({
    name: 'main-footer',

    computed: {
        ...mapState('training', ['config'])
    }
});
</script>

<style>
.footer3 {
    display: flex;
    align-items: center;
}
</style>